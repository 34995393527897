import {Image, Text, View} from "react-native";
import React from "react";

import themedStyles from "@components/header/styles";
import TimeDate from "@components/time_date";
import {HeaderLayout, HeaderSize, LanguageBarPosition} from "@themes/layout";
import {PinImage, SignBigImage} from "@utils/assets";
import {Theme} from "themes";

export interface Props {
    theme: Theme
    title: string
}

const Header = ({theme, title, ...props}: React.PropsWithChildren<Props>) => {
    const styles = themedStyles(theme);

    const compact = theme.layout.HeaderLayout == HeaderLayout.Compact
    const small = theme.layout.HeaderSize == HeaderSize.Small;
    const normal = theme.layout.HeaderSize == HeaderSize.Normal;

    if (compact) {
        return <View style={ styles.headerCompact }>
            <View style={ styles.zoneContainer }>
                <Image style={ compact ? [small ? styles.signBigCompactSmall : styles.signBigCompact] : styles.signBig } source={SignBigImage} />
                <Text style={ compact ? styles.zoneNameCompact : [styles.zoneName, small ? styles.zoneNameSmall : null] }>{title}</Text>
            </View>
            <TimeDate theme={theme}/>
        </View>
    }

    return <View style={ [styles.header, small ? styles.headerSmall : null, normal ? styles.headerNormal : null] }>
        <View style={styles.leftSide}>
            {theme.layout.LanguageBarPosition == LanguageBarPosition.Header ? <Image style={ [small ? styles.signBigCompactSmall : styles.signBigCompact] } source={PinImage} /> : <Image style={ title && title.length > 16 ? styles.signBigCompact : styles.signBig } source={SignBigImage} /> }
            <Text numberOfLines={1} style={ title && title.length > 16 ? styles.zoneNameCompact : [styles.zoneName, small ? styles.zoneNameSmall : null] }>
                {title}
            </Text>
        </View>
        <View style={ styles.rightSide }>
            <TimeDate theme={theme}/>
            {props.children}
        </View>
    </View>
};

export default Header;
