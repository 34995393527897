import {StyleSheet} from "react-native";
import {Theme} from "themes";

export default (theme: Theme) => StyleSheet.create({
    flagImageWrapper: {
        marginRight: 0.66 * theme.window.remSize,
        marginLeft: 0.66 * theme.window.remSize,
        opacity: 0.3
    },
    activeFlagImageWrapper: {
        opacity: 1
    },
    flagImage: {
        width: 3.06 * theme.window.remSize,
        height: 3.06 * theme.window.remSize
    },
    flagImageCompact: {
        width: 2.66 * theme.window.remSize,
        height: 2.66 * theme.window.remSize,
    },
    flagImageSmall: {
        width: 2.3 * theme.window.remSize,
        height: 2.3 * theme.window.remSize,
    },
});
