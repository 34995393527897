/**
 * Virtual keyboard
 */
'use strict';
import React from "react";
import {Platform, Text, TouchableOpacity, View} from 'react-native';

import {t} from '@utils/i18n';
import Key from "@components/key";
import themedStyles from "components/kiosk_keyboard/styles";
import {DeleteSvgImage} from "utils/assets";
import {Theme} from "themes";

export interface Props {
    theme: Theme
    chars: any
    onChar: (string) => void
}

const CompactKeyboard = ({theme, chars, onChar}: Props) => {
    const styles = themedStyles(theme);
    const web = Platform.OS === 'web'

    const numericRow = <View style={[styles.keysRow, web ? styles.keysRowWeb : null]}>
        <Key theme={theme} onPress={onChar}>1</Key>
        <Key theme={theme} onPress={onChar}>2</Key>
        <Key theme={theme} onPress={onChar}>3</Key>
        <Key theme={theme} onPress={onChar}>4</Key>
        <Key theme={theme} onPress={onChar}>5</Key>
        <Key theme={theme} onPress={onChar}>6</Key>
        <Key theme={theme} onPress={onChar}>7</Key>
        <Key theme={theme} onPress={onChar}>8</Key>
        <Key theme={theme} onPress={onChar}>9</Key>
        <Key theme={theme} onPress={onChar}>0</Key>
    </View>

    const deleteButton = <View style={[styles.deleteContainer, styles.deleteContainerCompact]}>
        <TouchableOpacity onPress={() => onChar('DEL')}>
            <View style={styles.deleteButton}>
                <View style={styles.deleteButtonIcon}>
                    <DeleteSvgImage/>
                </View>
                <Text style={styles.deleteButtonText}>{t('delete')}</Text>
            </View>
        </TouchableOpacity>
    </View>

    const rowAlphas = (row) => {
        return row.map((key) => {
            return <Key key={key} theme={theme} onPress={onChar}>{key}</Key>
        });
    };

    const rowChars = chars.map((row, i) => {
        return <View key={i} style={[styles.keysRow, web ? styles.keysRowWeb : null]}>
            {rowAlphas(row)}
        </View>
    });

    return <View style={[
        styles.keyboardContainer,
        web ? styles.keyboardContainerWeb : null,
    ]}>
        {
            numericRow
        }
        {
            rowChars
        }
        {
            deleteButton
        }
    </View>;
}

export default CompactKeyboard;
