import {StyleSheet} from "react-native";

export default StyleSheet.create({
    versionText: {
        position: 'absolute',
        bottom: 10,
        left: 10,
        color: '#fff',
        opacity: 0.4,
        fontFamily: 'Geogrotesque-Regular',
        zIndex: 9,
        backgroundColor: 'transparent'
    }
});
