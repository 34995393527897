import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {AppStackParamList} from "navigation/app";
import React, {useState} from "react";
import {Image, Text, View} from 'react-native';
import {useDispatch, useSelector} from "react-redux";

import {RootState} from "store";
import {logout} from "store/authSlice";
import themedStyles from "@screens/input/styles";
import {t} from '@utils/i18n';
import Header from "@components/header";
import Languages from "@components/languages";
import VersionText from "@components/version";
import KioskKeyboard from "@components/kiosk_keyboard";
import InputBox from "@components/input_box";

import InteractionProvider from "react-native-interaction-provider";
import * as Brightness from "expo-brightness";
import {KeyboardLayout, LanguageBarPosition, LogoPosition, LogoSize, LogoSpacing} from "themes/layout";
import {changeLanguage, switchLayout} from "store/settingsSlice";
import {clearValue, sendVehicleNumber} from "store/dataSlice";
import {ParkingCircleSvgImage, ParkingSquareSvgImage} from "utils/assets";
import { useTheme } from '@react-navigation/native';
import {Theme} from "themes";

export type InputScreenProps = {

};

function InputScreen({route, navigation}: NativeStackScreenProps<AppStackParamList, 'Input'>) {
    const theme = useTheme() as Theme;
    const styles = themedStyles(theme);

    const dispatch = useDispatch();
    const {product, screensaver_enabled, screensaver_show_after} = useSelector((state: RootState) => state.auth.kiosk);
    const {parking_provider} = useSelector((state: RootState) => state.kiosk.settings);

    const [subtitle, setSubtitle] = useState(null);
    const [info, setInfo] = useState(null);

    const [screensaver, setScreensaver] = useState(false);

    const onLanguageChange = (l) => {
        dispatch(changeLanguage(l))
    }

    const onSubmit = async () => {
        dispatch(sendVehicleNumber())
            .unwrap()
            .then((result) => {
                navigation.navigate('Result', result);
            }).catch((cause) => {
                if (cause === 'SWC') {
                    return dispatch(switchLayout());
                }
                if (cause === 'PIN') {
                    return dispatch(logout());
                }
                if (cause === 'AUTH') {
                    return dispatch(logout());
                }
                console.error("failed to send vehicle number", cause);
            }).finally(() => {
                dispatch(clearValue());
            });
    };

    const onDeviceActive = () => {
        if (!screensaver_enabled) {
            return;
        }

        setScreensaver(false);
        setBrightness(1);
    };

    const onDeviceInActive = () => {
        if (!screensaver_enabled) {
            return;
        }

        setScreensaver(true);
        setBrightness(0.1);
    };

    const setBrightness = (level) => {
        (async () => {
            const { status } = await Brightness.requestPermissionsAsync();
            if (status === 'granted') {
                Brightness.setSystemBrightnessAsync(level);
            }
        })();
    };

    const renderParkingLogo = () => {
        return <View style={[styles.parkingCircleWrapper]}>
            <View style={[styles.parkingCircleImage]}>
                <ParkingCircleSvgImage/>
            </View>
            { renderParkingProviderLogo() }
        </View>
    }

    const renderParkingProviderLogo = () => {
        if (!parking_provider || !parking_provider.kiosk_logo || !parking_provider.kiosk_logo_meta) {
            return null;
        }

        return <Image className="crisp"
                      style={[
                          parking_provider.kiosk_logo_meta,
                          styles.parkingProviderLogo,
                          theme.layout.LogoSize == LogoSize.Small ? styles.parkingProviderLogoSmall : null,
                          theme.layout.LogoPosition == LogoPosition.Center ? styles.parkingProviderLogoMiddle : null,
                          theme.layout.LogoSpacing == LogoSpacing.Centered ? styles.parkingProviderLogoCentered : null,
                      ]}
                      source={{uri: parking_provider.kiosk_logo}}/>;
    }

    return (
        <InteractionProvider
            timeout={(screensaver_show_after ? screensaver_show_after : 30 * 60) * 1000}
            onActive={onDeviceActive}
            onInactive={onDeviceInActive}
        >
            {screensaver ?
                <View style={styles.screensaverContainer}>
                    <View style={ styles.screensaverImage }>
                        <ParkingSquareSvgImage />
                    </View>
                    <Text style={ styles.screensaverText }>{t('tap_to_start')}</Text>
                </View>
                :
                <View style={styles.container}>
                    <Header theme={theme} title={product.name}>
                        { theme.layout.LanguageBarPosition == LanguageBarPosition.Header ? <Languages style={[styles.languages, theme.layout.LanguageBarPosition == LanguageBarPosition.Header ? styles.languagesHeader : null]} theme={theme} onChange={onLanguageChange}/> : null }
                    </Header>
                    { ['top', 'top-right'].includes(theme.layout.LanguageBarPosition) ? <Languages style={[styles.languages, theme.layout.LanguageBarPosition == LanguageBarPosition.Top ? styles.languagesCenter : styles.languagesTopRight]} theme={theme} onChange={onLanguageChange}/> : null }
                    <View style={[styles.middleContainer, (theme.layout.LogoPosition == LogoPosition.SideBySide ? styles.middleSideBySide : null), (theme.layout.LogoSpacing == LogoSpacing.SpaceBetween ? styles.middleSpaceBetween : null), (theme.layout.LogoSpacing == LogoSpacing.Centered ? styles.middleCentered : null)]}>
                        <View style={[styles.brandingContainer, (theme.layout.LogoPosition == LogoPosition.SideBySide ? styles.brandingSideBySide : null), (theme.layout.LogoSpacing == LogoSpacing.SpaceBetween ? styles.brandingSpaceBetween : null), (theme.layout.LogoSpacing == LogoSpacing.Centered ? styles.brandingCentered : null)]}>
                            { theme.layout.LanguageBarPosition == LanguageBarPosition.Logo ? <Languages style={styles.languagesLogo} theme={theme} onChange={onLanguageChange}/> : null }
                            { theme.layout.LanguageBarPosition == LanguageBarPosition.Header && theme.layout.LogoPosition == LogoPosition.SideBySide ? renderParkingLogo() : renderParkingProviderLogo() }
                        </View>
                        <View style={[styles.inputContainer, (theme.layout.LogoPosition == LogoPosition.SideBySide ? styles.inputContainerSideBySide : styles.inputContainerWide), (theme.layout.LogoSpacing == LogoSpacing.SpaceBetween ? styles.inputContainerSpaceBetween : null), (theme.layout.LogoSpacing == LogoSpacing.Centered ? styles.inputContainerCentered : null)]}>
                            <View style={styles.headLineContainer}>
                                <Text style={styles.headLine}>{t('enter_vehicle_number')}</Text>
                                {subtitle
                                    ? <Text style={styles.subHeadLine}>{subtitle}</Text>
                                    : null}
                            </View>
                            <InputBox theme={theme} placeholderText={t('use_the_keyboard')} minLength={2} secureTextEntry={false} onSubmit={onSubmit}/>
                        </View>
                    </View>
                    <View style={styles.keyboardContainer}>
                        <KioskKeyboard theme={theme}/>
                    </View>
                    <VersionText style={[theme.layout.KeyboardLayout == KeyboardLayout.UltraCompact ? {fontSize: 6}: null]} dark={false}/>
                </View>
            }
        </InteractionProvider>
    );
}

export default InputScreen;
