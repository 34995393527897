import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    popupAlert: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#FFF5F3',
        borderWidth: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 320,
        minHeight: 620
    },
    title: {
        fontSize: 42.5,
        color: '#E1592F',
        textAlign: 'center',
        fontFamily: 'Geogrotesque-Regular'
    },
    message: {
        fontSize: 24,
        color: '#E1592F',
        textAlign: 'center',
        fontFamily: 'Geogrotesque-Regular'
    },
    cancel: {
        color: '#FFFFFF',
        fontSize: 24,
        fontFamily: 'Geogrotesque-Regular',
        textAlign: 'center'
    },
    buttonTextWrapper: {
        backgroundColor: '#E1592F',
        minWidth: 160,
        height: 48,
        margin: 10,
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 46,
        justifyContent: 'center',
        alignItems: 'center'
    }
});
