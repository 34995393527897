import {KeyboardSize} from "themes/layout";
import {Dimensions} from "react-native";
import {ConnectionError} from "store/errors";

export function getBoolean(value){
    switch(value.toLowerCase()){
        case true:
        case "true":
        case 1:
        case "1":
        case "on":
        case "yes":
            return true;
        default:
            return false;
    }
}

export function calculateSizes(size: KeyboardSize) {
    const {height, width} = Dimensions.get('window');

    const containerHeightDefault = 338;
    const containerHeightCompact = 270;

    const fontDefault = 52;
    const fontCompact = 40;

    const rowHeightDefault = 80;

    let containerHeight = containerHeightDefault;
    let charFontSize = fontDefault;
    let rowHeight = rowHeightDefault;

    if (size == KeyboardSize.XLarge) {
        containerHeight = (height/2) - 20;
        rowHeight = (containerHeight/4) - 10;
        charFontSize = fontDefault + rowHeight/20;
    }

    return {
        containerHeight: containerHeight,
        rowHeight: rowHeight,
        charFontSize: charFontSize,
    };
}

export async function fetchWithTimeout(url, options, secondsTimeout = 5): Promise<Response> {
    let controller = new AbortController();

    return Promise.race([
        fetch(url, Object.assign(options, {signal: controller.signal})),
        new Promise<Response>((_, reject) => setTimeout(() => {
            controller.abort();
        }, secondsTimeout * 1000))
    ]);
}

