import {configureStore} from '@reduxjs/toolkit';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {Dispatch} from 'redux';
import settingsSlice, {changeLanguage, setLayout, setQrCode} from "@store/settingsSlice";
import kioskSlice from "@store/kioskSlice";
import authSlice, {Kiosk, setToken, setKiosk} from "@store/authSlice";
import dataSlice from "store/dataSlice";

export const readStateFromMemory = async (
    dispatch: Dispatch<any>,
) => {
    try {
        const token = await AsyncStorage.getItem('token') as string;
        const kiosk = await AsyncStorage.getItem('kiosk') as string;

        if (token !== null && kiosk !== null) {
            console.debug('read from memory', 'token and kiosk');

            const kioskSettings = JSON.parse(kiosk) as Kiosk;

            if (kioskSettings != null) {
                dispatch(setToken(token));
                dispatch(setKiosk(kioskSettings));
            }
        }

        const layout = await AsyncStorage.getItem('layout') as string;

        if (layout !== null) {
            console.debug('read from memory', 'layout', layout);

            dispatch(setLayout(layout));
        }

        const language = await AsyncStorage.getItem('language') as string;

        if (language !== null) {
            console.debug('read from memory', 'language', language);

            dispatch(changeLanguage(language));
        }

        const qrCode = await AsyncStorage.getItem('qrCode') as string;

        if (qrCode !== null) {
            console.debug('read from memory', 'qrCode', qrCode);

            dispatch(setQrCode(qrCode === 'true'));
        }
    } catch (e) {
        return '';
    }
};

function loginSettings(storeApi) {
    return function wrapDispatch(next) {
        return function handleAction(action) {
            console.debug('action.type', action.type);

            if (action.type == "auth/login/fulfilled") {
                console.debug("LOGIN SUCCESS");
            }

            if (action.type == "auth/setKiosk") {
                console.debug("KIOSK SETTINGS RESTORED");
            }

            return next(action)
        }
    }
}

export const store = configureStore({
    reducer: {
        auth: authSlice,
        kiosk: kioskSlice,
        settings: settingsSlice,
        data: dataSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(loginSettings),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
