import {Theme} from "themes/index";
import {LayoutConfig} from "themes/layout";
import Base from "themes/base";

export default (layout: LayoutConfig, height: number, width: number, scale: number, fontScale: number):Theme => {
    const BaseTheme = Base(layout, height, width, scale, fontScale)
    return {
        ...BaseTheme,
        colors: {
            ...BaseTheme.colors,
            text: "#565656",
            primary: "#007EB9",
            background: "#1A2A3A",
        },
        buttons: {
            ...BaseTheme.buttons,
            primary: "#1A2A3A",
            result: "#007E48",
        },
        wells: {
            ...BaseTheme.wells,
            primary: "#ff8200",
            primaryText: "#ffffff",
            primaryTextFontWeight: "bold",
            qrCodeBorder: "#000000",
        },
    };
};
