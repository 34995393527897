import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@store/index';
import api from '../api';
import {ConnectionError, RequestError} from "store/errors";
import AsyncStorage from "@react-native-async-storage/async-storage";

interface AuthResponse {
  token: string
  kiosk: Kiosk
  error?: RequestError;
}

export interface Kiosk {
  id: number;
  partner_product_id: number;
  product: KioskProduct;
  landlord: KioskLandlord;
  autlo_discount?: any;
  screensaver_enabled: boolean;
  screensaver_show_after?: number;
  qr_code_enabled: boolean;
}

interface KioskProduct {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
  is_template: boolean;
  deleted_at?: any;
  deleted_by?: any;
  partner_id: number;
  can_exceed_capacity: boolean;
  permit_count: number;
  created_by: number;
  updated_by: number;
  per_day?: any;
  frequency?: any;
  type: string;
  commission?: any;
  concurrent_parkings?: any;
  price?: any;
  comment?: any;
  sorting: number;
  can_use_cards: boolean;
  tenant_manage_permit_start: boolean;
  tenant_manage_permit_expiration: boolean;
  conference_url?: any;
  is_use_shared_session: boolean;
  date_expiration?: any;
  tenant_manage_permit_spot: boolean;
  parking_spot_number?: any;
  parent_id?: any;
  is_time_limit_edit: boolean;
  is_required_comment: boolean;
  time_limit: number;
  is_allow_guest_parking: boolean;
}

interface KioskLandlord {
  id: number;
  name: string;
  notification_email: string;
  created_at: Date;
  updated_at: Date;
  parent_partner_id?: any;
  address: string;
  deleted_at?: any;
  deleted_by?: any;
  contact_person?: any;
  phone?: any;
  phone_country?: any;
  created_by: number;
  updated_by: number;
  kiosk_keyboard_chars?: any;
  kiosk_colors?: any;
  kiosk_logo: string;
  kiosk_logo_meta: ImageMeta;
  is_superadmin: boolean;
  legal_address?: any;
  reg_code?: any;
  vat_number?: any;
  can_change_guest_parking_price: boolean;
}

interface ImageMeta {
  width: number
  height: number
}

export interface AuthState {
  loading: boolean
  token: string
  kiosk?: Kiosk
  error?: RequestError
}

const initialState: AuthState = {
  loading: true,
  token: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setToken: (state: AuthState, action: PayloadAction<string>) => {
      console.debug('setToken', action.payload);

      state.token = action.payload;

      AsyncStorage.setItem('token', state.token);
    },
    setKiosk: (state: AuthState, action: PayloadAction<Kiosk>) => {
      console.debug('setKiosk', action.payload);

      state.kiosk = action.payload;

      AsyncStorage.setItem('kiosk', JSON.stringify(state.kiosk));
    },
    logout: (state: AuthState, action: PayloadAction<void>) => {
      console.debug('logout');

      state.token = '';
      state.kiosk = null;
      state.error = null;

      AsyncStorage.removeItem('token');
      AsyncStorage.removeItem('kiosk');
    }
  },
  extraReducers: builder => {
    builder.addCase(login.pending, state => {
      console.debug('login pending');

      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      console.debug('login fulfiled');

      state.loading = false;
      state.token = action.payload.token;
      state.kiosk = action.payload.kiosk;

      AsyncStorage.setItem('token', state.token);
      AsyncStorage.setItem('kiosk', JSON.stringify(state.kiosk));
    });
    builder.addCase(login.rejected, (state, action) => {
      console.debug('login rejected', action.payload, action.error.code, action.error.message);

      let error = action.payload as RequestError;

      if (error == undefined) {
        error = ConnectionError
      }

      state.loading = false
      state.error = error;
    });
    builder.addCase(authenticate.pending, state => {
      console.debug('authenticate pending');

      state.loading = true;
    });
    builder.addCase(authenticate.fulfilled, (state, action) => {
      console.debug('authenticate fulfiled');

      state.loading = false;
      state.token = action.payload.token;
      state.kiosk = action.payload.kiosk;

      AsyncStorage.setItem('token', state.token);
      AsyncStorage.setItem('kiosk', JSON.stringify(state.kiosk));
    });
    builder.addCase(authenticate.rejected, (state, action) => {
      console.debug('authenticate rejected', action.payload, action.error.code, action.error.message);

      const error = action.payload as RequestError;
      state.loading = false
      state.error = error;
    });
  },
});

export default authSlice.reducer;

export const {setToken, setKiosk, logout} = authSlice.actions;

export const isAuthenticated = (state: RootState) => (state.auth.token && state.auth.kiosk);

export const login = createAsyncThunk<AuthResponse, string, {state: RootState}>(
  'auth/login',
  async (pin, {getState, rejectWithValue}) => {
    const resp = await api(getState()).post('login', {
      kiosk_pin: pin
    });
    if (resp.status === 401) {
      return rejectWithValue({name: 'invalid_pin', message: 'invalid_pin'})
    }
    if (resp.status !== 200) {
      console.error(`failed to login, status ${resp.status}`);
      return rejectWithValue('connect_failed')
    }
    return (await resp.json()) as AuthResponse
  },
);
export const authenticate = createAsyncThunk<AuthResponse, string, {state: RootState}>(
  'auth/authenticate',
  async (hash, {getState, rejectWithValue}) => {
    const resp = await api(getState()).post('login', {
      kiosk_hash: hash
    });
    if (resp.status === 401) {
      return rejectWithValue({name: 'invalid_pin', message: 'invalid_pin'})
    }
    if (resp.status !== 200) {
      console.error(`failed to get settings, status ${resp.status}`);
      return rejectWithValue('connect_failed')
    }
    return (await resp.json()) as AuthResponse
  },
);
