import {
    createSlice,
    createAsyncThunk,
    PayloadAction, SerializedError,
} from '@reduxjs/toolkit';
import {RootState} from './index';
import api from "../api";
import I18n from '@utils/i18n';
import {ConnectionError} from "store/errors";

export enum KioskStatus {
    initializing,
    initialized,
    loading,
    ready,
    failed
}

interface KioskState {
    status: KioskStatus
    loading: boolean
    currentRequestId: string
    error?: SerializedError
    settings: KioskSettings
}

interface KioskSettings {
    parking_provider?: ParkingProvider;
    languages: string[];
    default_language?: string;
    keyboard?: any;
    confirmation_timeout_s?: number;
    translations?: any;
    qr_code_enabled?: boolean;
}

interface ParkingProvider {
    id: number;
    name: string;
    kiosk_colors: string;
    kiosk_logo?: any;
    kiosk_logo_meta: string;
}

const initialState: KioskState = {
    status: KioskStatus.initializing,
    loading: false,
    currentRequestId: undefined,
    error: null,
    settings: {
        languages: [],
    },
};

const kioskSlice = createSlice({
    name: 'kiosk',
    initialState: initialState,
    reducers: {
        setStatus: (state: KioskState, action: PayloadAction<KioskStatus>) => {
            state.status = action.payload;
        },
        reset: (state: KioskState, action: PayloadAction<void>) => {
            state.error = null;
            state.status = KioskStatus.initialized;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchSettings.pending, (state, action) => {
            console.debug('fetchSettings.pending');

            if (!state.loading) {
                state.status = KioskStatus.loading
                state.loading = true
                state.currentRequestId = action.meta.requestId
            }
        });
        builder.addCase(fetchSettings.fulfilled, (state, action) => {
            console.debug('fetchSettings.fulfilled');

            const { requestId } = action.meta
            if (state.loading && state.currentRequestId === requestId) {
                state.status = KioskStatus.ready
                state.loading = false
                state.settings = action.payload
                state.currentRequestId = undefined

                // set some globals
                I18n.locale = state.settings.default_language;
                I18n.translations = state.settings.translations;
            }
        });
        builder.addCase(fetchSettings.rejected, (state, action) => {
            console.debug('fetchSettings.rejected');

            const { requestId } = action.meta
            if (state.loading && state.currentRequestId === requestId) {
                console.warn("action.error", action.error);
                let error = action.error;

                if (error.name === 'AbortError' || error.name === 'TypeError') {
                    error = ConnectionError
                }

                state.status = KioskStatus.failed
                state.loading = false
                state.error = error
                state.currentRequestId = undefined
            }
        })
    },
});

export default kioskSlice.reducer;

export const {setStatus, reset} =
    kioskSlice.actions;

export const fetchSettings = createAsyncThunk<
    KioskSettings,
    void,
    {state: RootState}
>('kiosk/fetchSettings', async (_, {getState, rejectWithValue}) => {
    const resp = await api(getState()).get('kiosk/settings');
    if (resp.status !== 200) {
        return rejectWithValue(`failed to get settings, status ${resp.status}`)
    }
    return (await resp.json()) as KioskSettings
});
