import {Theme} from "themes";
import {StyleSheet} from "react-native";

export default (theme: Theme) => StyleSheet.create({
    inputGroup: {
        width: '100%',
        maxWidth: 40 * theme.window.remSize,
        minWidth: 13.3 * theme.window.remSize,
        justifyContent: 'flex-end',
        flexDirection: 'row',
        marginTop: 1.33 * theme.window.remSize,
        marginBottom: 1.6 * theme.window.remSize,
        backgroundColor: '#ffffff',
    },
    inputGroupLarge: {
        maxWidth: 43.33 * theme.window.remSize,
    },

    inputWrapper: {
        flex: 1,
    },

    inputField: {
        flex: 1,
        color: '#565656',
        fontSize: 1.86 * theme.window.remFontSize,
        fontFamily: 'Geogrotesque-Regular',
        textAlign: 'center',
        outlineStyle: 'none',
    },
    inputFieldLarge: {
        fontSize: 2.66 * theme.window.remFontSize,
    },

    inputButtonWrapper: {
        padding: 0.53 * theme.window.remSize,
        justifyContent: 'center',
        alignItems: 'center',
    },

    inputButtonTextWrapper: {
        paddingVertical: 0.53 * theme.window.remSize,
        paddingHorizontal: 2.66 * theme.window.remSize,
        backgroundColor: '#EDEDED',
    },

    inputButtonText: {
        color: '#FFFFFF',
        fontSize: 1.86 * theme.window.remFontSize,
        fontFamily: 'Geogrotesque-Regular',
        textAlign: 'center'
    },
    inputButtonTextLarge: {
        fontSize: 2 * theme.window.remFontSize,
    },
    inputButtonActive: {
        backgroundColor: theme.buttons.primary
    },

    inputTextWrapper: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    inputText: {
        alignSelf: 'center',
        color: '#565656',
        fontSize: 1.86 * theme.window.remFontSize,
        fontFamily: 'Geogrotesque-Regular'
    },
    inputTextLarge: {
        fontSize: 2 * theme.window.remFontSize,
    },

    placeholderText: {
        color: '#C9C9C7',
    },
});
