import {StyleSheet} from "react-native";
import {Theme} from "themes";

export default (theme: Theme) => StyleSheet.create({
    keyContainer: {
        width: theme.keyboard.key.width,
        height: theme.keyboard.key.height,

        margin: theme.keyboard.key.margin,
        borderRadius: 3,
    },

    keyContainerCompact: {
        minWidth: theme.keyboard.key.minWidth,
        maxWidth: theme.keyboard.key.maxWidth,

        padding: 3,
        borderRadius: 2,

        justifyContent: 'center',
        alignItems: 'center'
    },

    touchBox: {
        zIndex: 2,

        width: '100%',
        height: '100%',
    },

    keyTextWrapper: {
        flex: 1,
        backgroundColor: '#303F4F',
        justifyContent: 'center',
        alignItems: 'center'
    },
    keyTextWrapperPressed: {
        backgroundColor: '#1E2E3D',
    },

    keyText: {
        fontSize: theme.keyboard.key.fontSize,
        color: '#FFFFFF',
        fontFamily: 'Geogrotesque-Regular',
        textAlign: 'center',
    },
    keyTextCompact: {
        fontSize: theme.keyboard.key.fontSize-14,
    },
    keyTextPressed: {
        color: '#1E2E3D',
    },

    tipContainer: {
        position: 'absolute',
        bottom: '100%',
        zIndex: 3,
        backgroundColor: '#1E2E3D',
        opacity: 1,
        borderWidth: 1,
        borderColor: '#1E2E3D',
        borderBottomLeftRadius: 30,
        borderBottomRightRadius: 30,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,

        justifyContent: 'center',

        height: theme.keyboard.tip.height,
        width: theme.keyboard.tip.width,
        marginLeft: -20,
    },

    tipContainerCompact: {
        width: '100%',
        minWidth: 20,
        backgroundColor: '#1E2E3D',
        opacity: 1,
        marginTop: -104,
        borderBottomWidth: 30,
        borderBottomColor: '#1E2E3D',
        borderBottomLeftRadius: 30,
        borderBottomRightRadius: 30,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        marginLeft: 0,
    },

    tipText: {
        color: '#FFFFFF',
        fontFamily: 'Geogrotesque-Regular',
        textAlign: 'center',
        fontSize: theme.keyboard.tip.fontSize,
    },

    tipTextCompact: {
        fontSize: theme.keyboard.tip.fontSize - 14,
    },
});
