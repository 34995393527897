import {Dimensions, PixelRatio} from "react-native";

export enum WindowSize {
    xsmall,
    small,
    medium,
    large,
    xlarge
}

export enum HeaderLayout {
    Compact = 'compact',
    Wide = 'wide',
}

export enum HeaderSize {
    Small = 'small',
    Normal = 'normal',
    Large = 'large'
}

export enum KeyboardLayout {
    UltraCompact = 'ultra-compact',
    Compact = 'compact',
    Wide = 'wide',
}

export enum KeyboardSize {
    Normal = 'normal',
    Large = 'large',
    XLarge = 'xlarge',
    XXLarge = 'xxlarge',
}

export enum LanguageBarPosition {
    Top = 'top',
    TopRight = 'top-right',
    Logo = 'logo',
    Header = 'header',
}

export enum LogoPosition {
    Center = 'center',
    SideBySide = 'side-by-side'
}

export enum LogoSize {
    Small = 'small',
    Normal = 'normal',
    Large = 'large'
}

export enum LogoSpacing {
    SpaceBetween = 'space-between',
    Centered = 'centered'
}

export enum InputSize {
    Normal = 'normal',
    Large = 'large'
}

export interface LayoutConfig {
    HeaderLayout: HeaderLayout
    HeaderSize: HeaderSize
    KeyboardLayout: KeyboardLayout
    KeyboardSize: KeyboardSize
    LanguageBarPosition: LanguageBarPosition
    LogoPosition: LogoPosition
    LogoSize: LogoSize
    LogoSpacing: LogoSpacing
    InputSize: InputSize
}

const layouts = {
    NormalWidescreen: {
        HeaderLayout: HeaderLayout.Wide,
        HeaderSize: HeaderSize.Normal,
        KeyboardLayout: KeyboardLayout.Wide,
        KeyboardSize: KeyboardSize.Normal,
        LanguageBarPosition: LanguageBarPosition.TopRight,
        LogoPosition: LogoPosition.Center,
        LogoSize: LogoSize.Normal,
        InputSize: InputSize.Normal,
    },
    NormalCompact: {
        HeaderLayout: HeaderLayout.Compact,
        HeaderSize: HeaderSize.Normal,
        KeyboardLayout: KeyboardLayout.Compact,
        KeyboardSize: KeyboardSize.Normal,
        LanguageBarPosition: LanguageBarPosition.Top,
        LogoPosition: LogoPosition.Center,
        LogoSize: LogoSize.Small,
        InputSize: InputSize.Normal,
    },
    EnlargedWidescreen: {
        HeaderLayout: HeaderLayout.Wide,
        HeaderSize: HeaderSize.Small,
        KeyboardLayout: KeyboardLayout.Compact,
        KeyboardSize: KeyboardSize.Large,
        LanguageBarPosition: LanguageBarPosition.Logo,
        LogoPosition: LogoPosition.SideBySide,
        LogoSize: LogoSize.Normal,
        LogoSpacing: LogoSpacing.SpaceBetween,
        InputSize: InputSize.Normal,
    },
    EnlargedCompact: {
        HeaderLayout: HeaderLayout.Wide,
        HeaderSize: HeaderSize.Small,
        KeyboardLayout: KeyboardLayout.Compact,
        KeyboardSize: KeyboardSize.Large,
        LanguageBarPosition: LanguageBarPosition.Header,
        LogoPosition: LogoPosition.SideBySide,
        LogoSize: LogoSize.Small,
        LogoSpacing: LogoSpacing.Centered,
        InputSize: InputSize.Large,
    }
}

export const Layouts: string[] = ['NormalWidescreen', 'NormalCompact', 'EnlargedWidescreen', 'EnlargedCompact']

export function nextLayout(layout: string): string {
    let nextLayoutIndex = Layouts.indexOf(layout) + 1;

    if (nextLayoutIndex >= Layouts.length) {
        nextLayoutIndex = 0;
    }

    return Layouts[nextLayoutIndex];
}

export function getLayout(layout: string): LayoutConfig {
    let config = Object.assign({}, layouts[layout]);
    const {height, width} = Dimensions.get('window');

    if (windowSize() < WindowSize.small) {
        config.KeyboardLayout = KeyboardLayout.UltraCompact
    }

    if (config.KeyboardLayout == KeyboardLayout.Wide && width > 1500 && height > 800) {
        config.KeyboardSize = KeyboardSize.XLarge
    }

    //if (config.KeyboardLayout == KeyboardLayout.Wide && windowSize() <= WindowSize.small) {
    if (config.KeyboardLayout == KeyboardLayout.Wide && width < 1133) {
        config = layouts.NormalCompact
    }

    return config;
}

// function scale(): number {
//     const {width, height} = Dimensions.get('screen');
//
//     const baseWidth = 375;
//     const baseHeight = 667;
//
//     const scaleWidth = width / baseWidth;
//     const scaleHeight = height / baseHeight;
//
//     // if scale is below 2 then its a mobile phone in landscape and increasing
//     // the size of elements makes it look bad, so add a simple threshold
//     console.debug('width >?< height', width, height, scaleWidth, scaleHeight, aspectRatio(), isTablet())
//
//     if (width >= height) {
//         return scaleWidth;
//     } else {
//         return scaleHeight;
//     }
// }

export function scale(size): number {
    const { width, height } = Dimensions.get('window');

    const baseWidth = 375;
    const baseHeight = 667;
    let baseScale;

    // on phones don`t scale anything
    if (!isTablet()) {
        baseScale = size * 0.5;
    } else {
        if (isLandscape()) {
            baseScale = width / baseWidth * size;
        } else {
            baseScale = height / baseHeight * size;
        }
    }

    // on really large screens make everything even larger
    if (windowSize() == WindowSize.xlarge) {
        baseScale = baseScale * 1.2;
    }

    console.debug('scale', width, height, baseScale, isLandscape(), isTablet(), windowSize());

    return baseScale;
}

export function verticalScale(size): number {
    const {width, height} = Dimensions.get('screen');
    const [shortDimension, longDimension] = width < height ? [width, height] : [height, width];

    const baseHeight = 667;

    return longDimension / baseHeight * size;
}

export function moderateScale(size, factor = 0.2): number {
    return size + ( scale(size) - size ) * factor;
}

export function moderateVerticalScale(size, factor = 0.2): number {
    return size + (verticalScale(size) - size) * factor;
}

export const aspectRatio = (): number => {
    const dimensions = Dimensions.get("screen")

    let STANDARD_ASPECT_RATIOS = [
        [1, '1:1'],
        [4 / 3, '4:3'], // 1.33
        [5 / 4, '5:4'], // 1.25
        [3 / 2, '3:2'], // 1.5
        [16 / 10, '16:10'], // 1.6
        [16 / 9, '16:9'], // 1.7
        [18 / 9, '18:9'], // 2
        [19 / 9, '19:9'], // 2.1
        [19.2 / 9, '19.2:9'],
        [19.3 / 9, '19.3:9'],
        [19.5 / 9, '19.5:9'],
        [20 / 9, '20:9'],
        [21.9 / 9, '21.9:9']
    ]
    let RATIOS = STANDARD_ASPECT_RATIOS.map(function (tpl) { return tpl[0] }).sort() as number[]

    function findClosest(arrSorted: number[], value: number) {
        let closest = arrSorted[0]
        let closestDiff = Math.abs(arrSorted[0] - value)
        for (let i = 1; i < arrSorted.length; i++) {
            let diff = Math.abs(arrSorted[i] - value)
            if (diff < closestDiff) {
                closestDiff = diff
                closest = arrSorted[i]
            } else {
                return closest
            }
        }
        return arrSorted[arrSorted.length - 1]
    }

    function aspectRatio(dim1: number, dim2: number) {
        let ratio = Math.max(dim1, dim2) / Math.min(dim1, dim2)

        let closest = findClosest(RATIOS, ratio)
        if (Math.abs(closest - ratio) <= 0.5) {
            return closest
        }

        return Math.round(ratio * 100) / 100
    }

    return aspectRatio(dimensions.height, dimensions.width)
}

/**
 *
 * @param {ScaledSize} dim the dimensions object
 * @param {*} limit the limit on the scaled dimension
 */
export const msp = (dim, limit) => {
    return (dim.scale * dim.width) >= limit || (dim.scale * dim.height) >= limit;
};

/**
 * Returns true if the screen is in portrait mode
 */
export const isPortrait = () => {
    const dim = Dimensions.get('screen');
    return dim.height >= dim.width;
};

/**
 * Returns true of the screen is in landscape mode
 */
export const isLandscape = () => {
    const dim = Dimensions.get('screen');
    return dim.width >= dim.height;
};

/**
 * Returns true if the device is a tablet
 */
export const isTablet = () => {
    const dim = Dimensions.get('window');
    return ((dim.scale < 2 && msp(dim, 1000)) || (dim.scale >= 2 && msp(dim, 1824)));
};

export const windowSize = () => {
    const dim = Dimensions.get('window');
    const [shortDimension, longDimension] = dim.width < dim.height ? [dim.width, dim.height] : [dim.height, dim.width];

    let dimension = shortDimension;

    if (isLandscape()) {
        dimension = longDimension;
    }

    return calculateSize(dimension);
}

export const windowHorizontalSizes = () => {
    const dim = Dimensions.get('window');
    if (isLandscape()) {
        return calculateSize(dim.width);
    } else {
        return calculateSize(dim.height);
    }
}

export const windowVerticalSizes = () => {
    const dim = Dimensions.get('window');
    if (isLandscape()) {
        return calculateSize(dim.height);
    } else {
        return calculateSize(dim.width);
    }
}

const calculateSize = (dimension) => {
    const dim = Dimensions.get('window');
    const ratio = PixelRatio.get();

    if ((dim.scale * dimension) >= ratio * 1920) {
        return WindowSize.xlarge;
    }
    if ((dim.scale * dimension) >= ratio * 1280) {
        return WindowSize.large;
    }
    if ((dim.scale * dimension) >= ratio * 960) {
        return WindowSize.medium;
    }
    if ((dim.scale * dimension) >= ratio * 600) {
        return WindowSize.small;
    }
    return WindowSize.xsmall;
}
