import {Keyboard, Platform, Text, TextInput, TouchableOpacity, TouchableWithoutFeedback, View} from "react-native";
import React, {useEffect, useRef, useState} from "react";

import themedStyles from "@components/input_box/styles";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "store";
import {setValue} from "store/dataSlice";
import {InputSize} from "themes/layout";
import {Theme} from "themes";

const re = new RegExp(/([a-z])(?=[0-9])/ig);
const re2 = new RegExp(/([0-9])(?=[a-z])/ig);

export interface Props {
    theme: Theme
    placeholderText: string
    minLength: number
    secureTextEntry: boolean
    onSubmit: (string) => void
}

const InputBox = ({theme, placeholderText, minLength, secureTextEntry, onSubmit}: Props) => {
    const styles = themedStyles(theme);

    const dispatch = useDispatch();
    const {value} = useSelector((state: RootState) => state.data);

    const [placeholderVisible, setPlaceholderVisible] = useState(true)
    const [valid, setValid] = useState(false)

    const textInputRef = useRef<TextInput>(null);

    const pointerEvents = Platform.OS === 'web' ? 'auto' : 'box-only';

    const inputLarge = theme.layout.InputSize == InputSize.Large;

    // focus input on render
    useEffect(() => {
        if (textInputRef && textInputRef.current) {
            textInputRef.current.focus()
        }
    }, []);

    useEffect(() => {
        setValid(value.length >= minLength ? true: false);
    }, [value]);

    const onInputChange = (newValue) => {
        dispatch(setValue(newValue.toUpperCase().replace(/\s/g, '').replace(re, "$1 ").replace(re2, "$1 ")));
    }

    const onKeyPress = (e) => {
        if(e.keyCode === 13) {
            submitValue()
        }
        textInputRef.current.focus()
    }

    const submitValue = () => {
        if (!valid) {
            return;
        }

        onSubmit(value);
    }

    // Capture keyboard on web
    if(Platform.OS === 'web') {
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            let key = evt.key;

            if(evt.keyCode === 13) {
                submitValue();
            }
            textInputRef.current.focus()
        }
    }

    return <View style={[styles.inputGroup, inputLarge ? styles.inputGroupLarge : null]}>
        <View style={styles.inputWrapper} pointerEvents={pointerEvents}>
            {Platform.OS === 'web' ?
                <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                    <TextInput
                        ref={textInputRef}
                        secureTextEntry={secureTextEntry}
                        style={[styles.inputField, inputLarge ? styles.inputFieldLarge : null]}
                        showSoftInputOnFocus={false}
                        placeholder={placeholderVisible ? placeholderText : ''}
                        placeholderTextColor="#C9C9C7"
                        keyboardType="none"
                        onChangeText={onInputChange}
                        onKeyPress={onKeyPress}
                        value={value}
                        className="inputBox"
                        onFocus={_ => { setPlaceholderVisible(false) }}
                        onBlur={_ => { setPlaceholderVisible(true) }}
                    />
                </TouchableWithoutFeedback>
                :
                <View style={styles.inputTextWrapper}>
                    <Text style={[styles.inputText, inputLarge ? styles.inputTextLarge : null, (!value ? styles.placeholderText : null)]}>{(!value ? (placeholderVisible ? placeholderText : '') : (secureTextEntry ? value.replace(/./g, "•") : value))}</Text>
                </View>
            }
        </View>
        <TouchableOpacity onPress={submitValue} style={styles.inputButtonWrapper}>
            <View style={[styles.inputButtonTextWrapper, valid && styles.inputButtonActive]}>
                <Text style={[styles.inputButtonText, inputLarge ? styles.inputButtonTextLarge : null]}>OK</Text>
            </View>
        </TouchableOpacity>
    </View>
};

export default InputBox;
