import {Theme} from "themes/index";
import {LayoutConfig} from "themes/layout";
import Base from "themes/base";

export default (layout: LayoutConfig, height: number, width: number, scale: number, fontScale: number):Theme => {
    const BaseTheme = Base(layout, height, width, scale, fontScale)
    return {
        ...BaseTheme,
        colors: {
            ...BaseTheme.colors,
            text: "#051622",
            primary: "#1A2A3A",
            background: "#1A2A3A",
        },
        buttons: {
            ...BaseTheme.buttons,
            primary: "#A5CE37",
            result: "#1A2A3A",
        },
        wells: {
            ...BaseTheme.wells,
            primary: "#A5CE37",
            primaryText: "#000000",
            primaryTextFontWeight: "normal",
            qrCodeBorder: "#0A7DCE",
        },
    };
};
