import {SerializedError} from "@reduxjs/toolkit";

export interface RequestError {
    name: string
    message: string
    error?: SerializedError
}

export const ConnectionError:RequestError = {
    name: "connect_failed",
    message: "check_internet",
}
