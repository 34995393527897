
const percentageCalculation = (max: number, val: number) => max * (val / 100);

const fontCalculation = (height: number, width: number, val: number) => {
    const widthDimension = height > width ? width : height;
    const aspectRatioBasedHeight = (16 / 9) * widthDimension;
    return percentageCalculation(
        Math.sqrt(
            Math.pow(aspectRatioBasedHeight, 2) + Math.pow(widthDimension, 2)
        ),
        val
    );
};

export const responsiveSize = (f: number, windowHeight: number, windowWidth: number) => {
    const size = fontCalculation(windowHeight, windowWidth, f);
    return Math.round(size)
};

export const responsiveHeight = (h: number, windowHeight: number) => {
    const size = percentageCalculation(windowHeight, h);
    return Math.round(size);
};

export const responsiveWidth = (w: number, windowWidth: number) => {
    const size = percentageCalculation(windowWidth, w);
    return Math.round(size);
};

export const responsiveFontSize = (f: number, windowHeight: number, windowWidth: number) => {
    const size = fontCalculation(windowHeight, windowWidth, f);
    return Math.round(size);
};
