import {Theme, ThemedStyledSheet} from "themes";
import {LayoutConfig} from "themes/layout";

export default (theme: Theme, layout: LayoutConfig) => ThemedStyledSheet.create(theme, layout, {
    container: {
        minHeight: '100%',
        backgroundColor: '#EDEDED',
        minWidth: 14.53 * theme.window.remSize
    },
    contentHost: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'stretch',
    },
    containerError: {
        backgroundColor: '#FFF5F3'
    },
    textError: {
        textAlign: 'center',
        color: '#E1592F'
    },
    resultContainer: {
        flex: 2,
        justifyContent: 'center',
        alignItems: 'center',
    },
    iContainer: {
        width: '97%',
        maxWidth: 22.7 * theme.window.remSize,
        minWidth: 9.08 * theme.window.remSize,
        justifyContent: 'flex-end',
        flexDirection: 'row',
        marginTop: 0.9 * theme.window.remSize,
        marginBottom: 1.08 * theme.window.remSize,
        height: 3.08 * theme.window.remSize,
        backgroundColor: '#ffffff'
    },
    logoContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    vehicleNumber: {
        fontSize: 2.18 * theme.window.remFontSize,
        margin: 0.9 * theme.window.remSize,
        color: '#007EB9',
        fontFamily: 'Geogrotesque-Regular'
    },
    headLine: {
        fontSize: 1.93 * theme.window.remFontSize,
        color: '#565656',
        textAlign: 'center',
        fontFamily: 'Geogrotesque-Regular',
        marginBottom: 0.9 * theme.window.remSize,
    },
    subHeadContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    timeResult: {
        fontSize: 2.18 * theme.window.remFontSize,
        margin: 0.9 * theme.window.remSize,
        color: '#007EB9',
        fontFamily: 'Geogrotesque-Regular'
    },
    dateResult: {
        fontSize: 1.27 * theme.window.remFontSize,
        color: '#007EB9',
    },
    subHeadLine: {
        fontSize: 1.27 * theme.window.remFontSize,
        color: '#565656',
        textAlign: 'center',
        fontFamily: 'Geogrotesque-Regular'
    },
    inputButtonText: {
        color: '#FFFFFF',
        fontSize: 1.27 * theme.window.remFontSize,
        fontFamily: 'Geogrotesque-Regular',
        textAlign: 'center'
    },
    inputButtonTextWrapper: {
        margin: 0.9 * theme.window.remSize,
        backgroundColor: theme.buttons.result,
        width: 5 * theme.window.remSize,
        height: 3 * theme.window.remSize,
        justifyContent: 'center',
        alignItems: 'center'
    },
    errorButton: {
        backgroundColor: '#EDEDED'
    },
    errorButtonActive: {
        backgroundColor: '#E1592F',
    },
    inputBox: {
        flex: 1,
        paddingLeft: 0.45 * theme.window.remSize
    },
    inputField: {
        flex: 1,
        color: '#565656',
        fontSize: 1.27 * theme.window.remFontSize,
        fontFamily: 'Geogrotesque-Regular',
        textAlign: 'center'
    },
    inputWrapper: {
        height: '100%',
        width: 5 * theme.window.remSize,
        padding: 0.45 * theme.window.remSize,
        justifyContent: 'center',
        alignItems: 'center'
    },
    errorInputWrapper: {
        height: 3.08 * theme.window.remSize,
        width: 5 * theme.window.remSize,
        padding: 0.45 * theme.window.remSize,
        justifyContent: 'center',
        alignItems: 'center'
    },

    qrCodeContainer: {
        overflow: 'hidden',
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        paddingHorizontal: 0.9 * theme.window.remSize,
        backgroundColor: theme.wells.primary,
    },
    qrCodeInstructions: {
        alignItems: 'center',
    },
    qrCodeInstructionsAbove: {
        display: 'none',
    },
    qrCodeInstructionsBelow: {
        marginBottom: 1.81 * theme.window.remSize,
    },
    qrCodeInstructionsText: {
        color: theme.wells.primaryText,
        fontSize: 1.27 * theme.window.remFontSize,
        fontFamily: 'Geogrotesque-Regular',
        fontWeight: theme.wells.primaryTextFontWeight,
    },
    qrCodePhone: {
        display: 'none',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%',
        padding: 0.45 * theme.window.remSize,
        position: "relative",
        marginVertical: 0.45 * theme.window.remSize,
    },
    qrCodePhoneTab: {
        width: '20%',
        height: 0.09 * theme.window.remSize,
        borderWidth: 0.09 * theme.window.remSize,
        borderRadius: 0.9 * theme.window.remSize,
        backgroundColor: 'black',
    },
    qrCodeParking: {
        margin: 0.45 * theme.window.remSize,
    },
    qrCodePhoneImage: {
        flex: 1,
        aspectRatio: 0.5,
    },
    qrCodePhoneOverlay: {
        padding: 0.27 * theme.window.remSize,
        alignItems: 'center',
        height: '90%',
        minWidth: 6 * theme.window.remSize,
        borderWidth: 0.14 * theme.window.remSize,
        borderRadius: 0.9 * theme.window.remSize,
        backgroundColor: 'white',
    },
    parkingTime: {
        fontSize: 1.09 * theme.window.remFontSize,
    },
    parkingImage: {
        resizeMode: "contain"
    },
    parkingProviderLogoContainer: {
        flex: 1,
        width: '100%',
    },
    parkingProviderLogo: {
        resizeMode: "contain",
        width: undefined,
        height: '100%',
    },
    qrCodeWrapper: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        margin: 0.45 * theme.window.remSize,
        aspectRatio: 1,
    },
    qrCode: {
        aspectRatio: 1,
        borderWidth: 0.09 * theme.window.remSize,
        borderColor: theme.wells.qrCodeBorder,
        borderRadius: 0.27 * theme.window.remSize,
        padding: 0.27 * theme.window.remSize,
        backgroundColor: 'white',
    },
    qrCodeImage: {
        height: '100%',
        width: undefined,
        aspectRatio: 1,
    },
    '@media (min-width: 800)': {
        qrCodeContainer: {
            flex: 1,
            flexDirection: "row",
        },
        qrCodeInstructions: {
            flex: 3,
        },
        qrCodeInstructionsAbove: {
            display: 'flex',
        },
        qrCodeInstructionsBelow: {
            display: 'none',
        },
        qrCodePhone: {
            display: 'flex',
        },
        qrCode: {
            maxWidth: '90%',
            maxHeight: '90%',
        },
        qrCodeWrapper: {
            flexDirection: 'column',
        }
    },
});
