import {createSlice, PayloadAction,} from '@reduxjs/toolkit';
import {getLayout, LayoutConfig, nextLayout} from "@themes/layout";
import Constants from "expo-constants";
import I18n from '@utils/i18n';
import AsyncStorage from "@react-native-async-storage/async-storage";

const COUNTRY = Constants.manifest.extra.COUNTRY || Constants.manifest.extra.VARIANT || 'ee';
const DEFAULT_LAYOUT = Constants.manifest.extra.LAYOUT || 'NormalWidescreen';

interface SettingsState {
    country: string;
    language: string;
    product: any;
    layout: string;
    keyboardType: string;
    forcedLayout: boolean;
    forcedCompact: boolean;
    visuals: any;
    screensaver: any;
    qrCode: boolean;
}

const initialState: SettingsState = {
    country: COUNTRY,
    language: null,
    product: {},
    layout: DEFAULT_LAYOUT,
    keyboardType: 'normal',
    forcedLayout: false,
    forcedCompact: false,
    visuals: {
        landlord_logo: null,
        kiosk_logo: null,
        colors: {},
        discount: null
    },
    screensaver: {
        enabled: false,
        timeout: 30 * 60
    },
    qrCode: false,
};

interface SetupAction {
    product: any;
    landlord_logo: string;
    landlord_logo_meta: string;
    discount: string;
    screensaver_enabled: boolean;
    screensaver_show_after: boolean;
    qr_code_enabled: boolean;
}

const settingsSlice = createSlice({
    name: 'settings',
    initialState: initialState,
    reducers: {
        changeLanguage: (state: SettingsState, action: PayloadAction<string>) => {
            console.debug('changeLanguage', action.payload);

            state.language = action.payload

            // set some globals
            I18n.locale = state.language;

            AsyncStorage.setItem('language', state.language);
        },
        setLayout: (state: SettingsState, action: PayloadAction<string>) => {
            console.debug('setLayout', action.payload);

            state.layout = action.payload
            state.forcedLayout = true

            console.debug('layout', state.layout);
            AsyncStorage.setItem('layout', state.layout);
        },
        switchLayout: (state: SettingsState, action: PayloadAction<null>) => {
            state.layout = nextLayout(state.layout)
            state.forcedLayout = true

            console.debug('switchLayout', state.layout);
            AsyncStorage.setItem('layout', state.layout);
        },
        setQrCode: (state: SettingsState, action: PayloadAction<boolean>) => {
            console.debug('setQrCode', action.payload);

            state.qrCode = action.payload
        },
        switchQrCode: (state: SettingsState, action: PayloadAction<null>) => {
            state.qrCode = !state.qrCode

            console.debug('toggleQrCode', state.qrCode);
            AsyncStorage.setItem('qrCode', String(state.qrCode));
        },
        setup: (state: SettingsState, action: PayloadAction<SetupAction>) => {
            state.product = action.payload.product

            if(action.payload.landlord_logo && action.payload.landlord_logo_meta) {
                state.visuals.landlord_logo.uri = action.payload.landlord_logo
                state.visuals.landlord_logo.meta = action.payload.landlord_logo_meta
            }

            if (action.payload.discount) {
                state.visuals.discount = action.payload.discount;
            }

            if(action.payload.screensaver_enabled) {
                state.screensaver.enabled = action.payload.screensaver_enabled
            }

            if (action.payload.screensaver_show_after) {
                state.screensaver.timeout = action.payload.screensaver_show_after;
            }
        },
    },
});

export default settingsSlice.reducer;

export const {
    changeLanguage,
    setLayout,
    switchLayout,
    setQrCode,
    switchQrCode,
    setup,
} = settingsSlice.actions;
