/**
 * Virtual keyboard
 */
'use strict';
import React from "react";
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from "store";
import {KeyboardLayout} from "themes/layout";
import {addChar, clearValue} from "store/dataSlice";
import CompactKeyboard from "components/kiosk_keyboard/compact";
import UltraCompactKeyboard from "components/kiosk_keyboard/ultra_compact";
import WideKeyboard from "components/kiosk_keyboard/wide";
import {Theme} from "themes";

export interface Props {
    theme: Theme
}

const KioskKeyboard = ({theme}: Props) => {
    const dispatch = useDispatch();

    const {keyboard} = useSelector((state: RootState) => state.kiosk.settings);
    const {country, keyboardType} = useSelector((state: RootState) => state.settings);
    const chars = keyboard[country].general;

    const onChar = (c) => {
        if (c == 'DEL' && keyboardType == 'challenged') {
            dispatch(clearValue());
        } else {
            dispatch(addChar(c));
        }
    }

    console.log("!!! RENDER KioskKeyboard", theme.layout.KeyboardLayout)

    switch (theme.layout.KeyboardLayout) {
        case KeyboardLayout.UltraCompact:
            return <UltraCompactKeyboard theme={theme} chars={chars} onChar={onChar} />
        case KeyboardLayout.Compact:
            return <CompactKeyboard theme={theme} chars={chars} onChar={onChar} />
        case KeyboardLayout.Wide:
            return <WideKeyboard theme={theme} chars={chars} onChar={onChar} />
    }
}

export default KioskKeyboard;
