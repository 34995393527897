import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {AppStackParamList} from "navigation/app";
import React, {useEffect} from "react";
import {Text, Image, TouchableOpacity, View} from 'react-native';
import {Check} from "api/models";
import Header from "@components/header";
import VersionText from "@components/version";
import QRCode from "@components/qr_code";
import themedStyles from "@screens/result/styles";
import {t} from '@utils/i18n';
import {useSelector} from "react-redux";
import {RootState} from "store";
import TimerMixin from "react-timer-mixin/TimerMixin";
import {ParkingCircleSvgImage} from "utils/assets";
import { useTheme } from '@react-navigation/native';
import moment from "moment";
import {Theme} from "themes";

export type ResultScreenProps = {
    Result: Check
};

function ResultScreen({route, navigation}: NativeStackScreenProps<AppStackParamList, 'Result'>) {
    const result = route.params;

    const {layout, qrCode} = useSelector((state: RootState) => state.settings);
    const {parking_provider, confirmation_timeout_s} = useSelector((state: RootState) => state.kiosk.settings);
    const {product, qr_code_enabled} = useSelector((state: RootState) => state.auth.kiosk);

    const qrCodeEnabled = qrCode || qr_code_enabled;

    const theme = useTheme() as Theme
    const styles = themedStyles(theme, theme.layout);

    useEffect(() => {
        let timeout = confirmation_timeout_s;

        if (!timeout) {
            timeout = 5;
        }

        // in case we have the qr code increase the timeout
        if (qrCodeEnabled) {
            timeout = timeout * 5;
        }

        var timer = TimerMixin.setTimeout(onOk, 1000 * (result.success ? timeout : 8));

        return () => {
            TimerMixin.clearTimeout(timer);
        };
    }, []);

    const onOk = () => {
        navigation.navigate('Input');
    }

    const formatSubtitles = (info: string[]) => {
        if (!info || info.length == 0) {
            return null;
        }

        let res = [];

        if(info[1]) {
            res.push(<Text key={0} style={[styles.subHeadLine, !result.success && styles.textError]}>{info[0]}</Text>)
            res.push(<View key={1} style={styles.subHeadContainer}>
                <Text style={[styles.timeResult, !result.success && styles.textError]}>{info[1]}</Text>
            </View>)
        } else {
            res = info.map((i, k) => {
                return <Text key={k} style={[styles.subHeadLine, !result.success && styles.textError]}>{i}</Text>
            });
        }

        return res;
    }

    const getEndTime = () => {
        return (moment(result.end_time)).format('HH:mm');
    }

    const renderQrCode = (value: string) => {
        return <View style={[styles.qrCodeContainer]}>
            <View style={[styles.qrCodeInstructions, styles.qrCodeInstructionsAbove]}>
                <Text style={styles.qrCodeInstructionsText}>{t('qr_code_scan')}</Text>
            </View>
            <View style={styles.qrCodePhone}>
                <View style={styles.qrCodePhoneOverlay}>
                    <View style={styles.qrCodePhoneTab}></View>
                    <ParkingCircleSvgImage style={styles.qrCodeParking} height="40%" />
                    <View style={[styles.parkingProviderLogoContainer]}>
                        {(parking_provider && parking_provider.kiosk_logo)
                            ? <Image className="crisp"
                                     style={[styles.parkingProviderLogo]} source={{uri: parking_provider.kiosk_logo}}/>
                            : null}
                    </View>
                    <Text style={[styles.parkingTime]}>{ getEndTime() }</Text>
                </View>
            </View>
            <View style={styles.qrCodeWrapper}>
                <View style={styles.qrCode}>
                    <QRCode
                        value={value}
                        style={styles.qrCodeImage}
                    />
                </View>
            </View>
            <View style={[styles.qrCodeInstructions, styles.qrCodeInstructionsBelow]}>
                <Text style={styles.qrCodeInstructionsText}>{t('qr_code_scan')}</Text>
            </View>
        </View>
    }

    return (
        <View style={[styles.container, !result.success && styles.containerError]}>
            <Header theme={theme} title={product.name}/>
            <View style={styles.contentHost}>
                <View style={styles.resultContainer}>
                    {result.success ? <Text style={[styles.vehicleNumber]}>{result.vehicle_reg}</Text> : null}
                    <Text style={[styles.headLine, !result.success && styles.textError]}>{result.message}</Text>
                    {formatSubtitles(result.info)}
                    <TouchableOpacity onPress={onOk} style={styles.errorInputWrapper}>
                        <View style={styles.inputButtonTextWrapper}>
                            <Text style={styles.inputButtonText}>OK</Text>
                        </View>
                    </TouchableOpacity>
                </View>
                {
                    ( qrCodeEnabled && (moment(result.end_time)).isAfter(moment()) ) ? renderQrCode(result.qr_code_data) : null
                }
            </View>
            <VersionText dark={true}/>
        </View>
    );
}

export default ResultScreen;
