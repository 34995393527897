import * as React from 'react';
import { useSelector } from 'react-redux'
import {NavigationContainer} from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import PinView from '@screens/pin'
import InputScreen, {InputScreenProps} from "@screens/input";
import ResultScreen, {ResultScreenProps} from "@screens/result";
import {isAuthenticated} from "store/authSlice";
import {getThemeStyles} from "themes";
import {useWindowDimensions} from "react-native";
import {RootState} from "store";
import {getLayout} from "themes/layout";

export type AppStackParamList = InputScreenProps & ResultScreenProps;

const Stack = createNativeStackNavigator();
const AppNavigation = () => {
    const authenticated = useSelector(isAuthenticated)
    const {layout} = useSelector((state: RootState) => state.settings);
    const {height, width, scale, fontScale} = useWindowDimensions();
    const theme = getThemeStyles(getLayout(layout), height, width, scale, fontScale);

    return (
        <NavigationContainer theme={theme}>
            <Stack.Navigator initialRouteName={!authenticated ? "PinView" : "Input"} screenOptions={{
                headerShown: false,
            }}>
                {!authenticated ? (
                    <Stack.Screen
                        name="PinView"
                        component={PinView}
                    />
                ) : (
                    <Stack.Group>
                        <Stack.Screen name="Input" component={InputScreen}/>
                        <Stack.Screen name="Result" component={ResultScreen}/>
                    </Stack.Group>
                )}
            </Stack.Navigator>
        </NavigationContainer>
    );
}

export default AppNavigation;
