import Constants from 'expo-constants';
import * as SplashScreen from 'expo-splash-screen';
import AnimatedAppLoader from "@screens/loader";
import {store} from "store";
import {Provider} from "react-redux";
import AppNavigation from "navigation/app";

// Instruct SplashScreen not to hide yet, we want to do this manually
SplashScreen.preventAutoHideAsync().catch(() => {
    /* reloading the app might trigger some race conditions, ignore them */
});

export default function App() {
    return (
        <Provider store={store}>
            <AnimatedAppLoader image={{ uri: Constants.manifest.splash.image }}>
                <AppNavigation />
            </AnimatedAppLoader>
        </Provider>
    );
}
