import ParkingCircleSvg from './../../assets/img/parking_circle.svg';
import ParkingSquareSvg from './../../assets/img/parking_square.svg';
import DeleteImageSvg from './../../assets/img/del.svg';
import LayoutImageSvg from './../../assets/img/layout.svg';
import PhoneSvg from './../../assets/img/phone.svg';
import en from './../../assets/img/flags/en.png';
import et from './../../assets/img/flags/et.png';
import dk from './../../assets/img/flags/dk.png';
import fi from './../../assets/img/flags/fi.png';
import lv from './../../assets/img/flags/lv.png';
import pl from './../../assets/img/flags/pl.png';
import ru from './../../assets/img/flags/ru.png';
import sv from './../../assets/img/flags/sv.png';
import EnglishImageSvg from './../../assets/img/flags/en.svg';
import EstonianImageSvg from './../../assets/img/flags/et.svg';
import DanishImageSvg from './../../assets/img/flags/dk.svg';
import FinnishImageSvg from './../../assets/img/flags/fi.svg';
import LatvianImageSvg from './../../assets/img/flags/lv.svg';
import PolishImageSvg from './../../assets/img/flags/pl.svg';
import RussianImageSvg from './../../assets/img/flags/ru.svg';
import SwedishImageSvg from './../../assets/img/flags/sv.svg';

export const FlagsImages = {
    en, et, dk, fi, lv, pl, ru, sv
}

export const FlagsSvgImages = {
    en: EnglishImageSvg,
    et: EstonianImageSvg,
    dk: DanishImageSvg,
    fi: FinnishImageSvg,
    lv: LatvianImageSvg,
    pl: PolishImageSvg,
    ru: RussianImageSvg,
    sv: SwedishImageSvg,
}

export const DeleteSvgImage = DeleteImageSvg;
export const LayoutSvgImage = LayoutImageSvg;

export const ParkingCircleImage = require('./../../assets/img/parking_circle.png');
export const ParkingCircleSvgImage = ParkingCircleSvg;
export const ParkingSquareSvgImage = ParkingSquareSvg;
export const SignBigImage = require('./../../assets/img/sign_big.png');
export const PinImage = require('./../../assets/img/pin.png');
export const PhoneImage = require('./../../assets/img/phone.png');
export const PhoneSvgImage = PhoneSvg;
export const qrCodeImage = require('./../../assets/img/qr.png');
export const qrCodeOffImage = require('./../../assets/img/qr_off.png');
