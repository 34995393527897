import {StyleSheet} from "react-native";

export default StyleSheet.create({
    container: {
        flexDirection: 'row',
        paddingRight: 10,
        zIndex: 100,
    },
    containerCompact: {
        flexDirection: 'row',
        zIndex: 100
    },
});
