import React from "react";
import {View, ViewStyle} from 'react-native';
import {useSelector} from 'react-redux'
import Flag from "@components/flag";
import styles from './styles'
import {RootState} from "store";
import {KeyboardLayout, LayoutConfig} from "themes/layout";
import {Theme} from "themes";

export interface Props {
  theme: Theme
  style?: ViewStyle|ViewStyle[]
  onChange: (language: string) => void
}

const Languages = ({theme, style, onChange}: Props) => {
  const {language} = useSelector((state: RootState) => state.settings);
  const {default_language, languages} = useSelector((state: RootState) => state.kiosk.settings);

  const compact = theme.layout.KeyboardLayout == KeyboardLayout.Compact
  const current_language = language ?? default_language;

  const flags = languages.map( l => <Flag key={l} language={l} active={l == current_language} theme={theme} onPress={() => onChange(l)} />)

  return (
      <View style={[compact ? styles.containerCompact : styles.container, style]}>
        {flags}
      </View>
  );
}

export default Languages;
