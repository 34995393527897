import React, {useEffect, useState} from "react";
import TimerMixin from "react-timer-mixin/TimerMixin";
import {Text, View} from "react-native";

import baseStyles from "@components/time_date/styles";
import {HeaderLayout} from "@themes/layout";
import {t} from '@utils/i18n';
import {Theme} from "themes";

export interface Props {
    theme: Theme
}

const TimeDate = ({ theme }: Props) => {
    const styles = baseStyles(theme);

    const compact = theme.layout.HeaderLayout == HeaderLayout.Compact

    const [clock, setClock] = useState('')
    const [date, setDate] = useState('')

    useEffect(() => {
        var timer = TimerMixin.setInterval(updateTimeAndDate, 500);

        return () => {
            TimerMixin.clearTimeout(timer);
        };
    }, []);

    const getDate = () => {
        const d = new Date();
        const m = t('months')[d.getMonth()];
        return ("00" + d.getDate()).substr(-2) + ' '  + m + ' ' + d.getFullYear()
    }

    const getTime = () => {
        const d = new Date();
        return ("00" + d.getHours()).substr(-2) + ':' + ("00" + d.getMinutes()).substr(-2)
    };

    const updateTimeAndDate = () => {
        setClock(getTime());
        setDate(getDate());
    }

    return (
        <View style={compact ? styles.timeDateCompact : styles.timeDate}>
            <Text style={[styles.time, compact ? styles.timeCompact : null]}>{clock}{compact ? ', ' : ''}</Text>
            <Text style={[styles.date, compact ? styles.dateCompact : null]}>{date}</Text>
        </View>
    );
}

export default TimeDate;
