import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "store/index";
import api from "api";
import {setToken} from "store/authSlice";
import {Check} from "api/models";

interface DataState {
    value: string;
}

const initialState: DataState = {
    value: ''
};

const dataSlice = createSlice({
    name: 'data',
    initialState: initialState,
    reducers: {
        setValue: (state: DataState, action: PayloadAction<string>) => {
            state.value = action.payload
        },
        addChar: (state: DataState, action: PayloadAction<string>) => {
            const val = (action.payload == 'DEL' ? state.value.slice(0, -1) : (state.value + action.payload))
            state.value = val;
        },
        clearValue: (state: DataState, action: PayloadAction<string>) => {
            state.value = '';
        }
    },
});

export default dataSlice.reducer;

export const {
    setValue,
    addChar,
    clearValue
} = dataSlice.actions;

export const sendVehicleNumber = createAsyncThunk<
    DataState,
    void,
    {state: RootState}
>('data/sendVehicleNumber', async (_, {dispatch, getState, rejectWithValue}) => {
    const value = getState().data.value;
    const language = getState().settings.language;

    if (value === 'SWC') {
        return rejectWithValue("SWC");
    }

    if (value === 'PIN' || value === 'PINPAGE') {
        return rejectWithValue("PIN");
    }

    try {
        const resp = await api(getState()).post('kiosk/check', {
            vehicle_reg: value,
            language: language
        });

        // we got a new token store it
        if (resp.headers.get('X-Refresh-Token') != '') {
            dispatch(setToken(resp.headers.get('X-Refresh-Token')));
        }

        // auth required clear toke.
        if (resp.status === 401) {
            return rejectWithValue("AUTH");
        }

        if (resp.status !== 200) {
            console.error(`failed to get settings, status ${resp.status}`)
        }

        return (await resp.json()) as Check;
    } catch (e) {
        rejectWithValue(e);
        console.error('api check failed', e);
    }
});
