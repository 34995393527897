import {DefaultTheme} from "@react-navigation/native";
import {Theme} from "themes/index";
import {responsiveFontSize, responsiveHeight, responsiveSize, responsiveWidth} from "themes/utils";
import {KeyboardLayout, KeyboardSize, LayoutConfig} from "themes/layout";

export default (layout: LayoutConfig, height: number, width: number, scale: number, fontScale: number):Theme => {
    // default with ultra-compact (mobile) keyboard
    var theme = {
        ...DefaultTheme,
        layout: layout,
        window: {
            height: height,
            width: width,
            scale: scale,
            fontScale: fontScale,
            remSize: responsiveSize(1, height, width),
            remWidth: responsiveWidth(1, width),
            remHeight: responsiveHeight(1, height),
            remFontSize: responsiveFontSize(1, height, width) * fontScale,
        },
        colors: {
            ...DefaultTheme.colors,
            text: "#565656",
            primary: "#007EB9",
            background: "#1A2A3A",
        },
        buttons: {
            primary: "#1A2A3A",
            result: "#1A2A3A",
        },
        wells: {
            primary: "#007EB9",
            primaryText: "#000000",
            primaryTextFontWeight: "normal",
            qrCodeBorder: "#0A7DCE",
        },
        keyboard: {
            container: {
                height: responsiveSize(17, height, width),
            },
            key: {
                fontSize: responsiveFontSize(2.365, height, width),
                width: responsiveSize(3.5, height, width),
                height: responsiveSize(3.5, height, width),
                margin: responsiveSize(0.2, height, width),
            },
            tip: {
                fontSize: responsiveFontSize(4.2, height, width),
                width: responsiveSize(5.5, height, width),
                height: responsiveSize(5.5, height, width),
            },
            delete: {
                fontSize: responsiveFontSize(1.61, height, width),
                iconWidth: responsiveSize(3.5, height, width),
                iconHeight: responsiveSize(3.5, height, width),
                containerHeight: undefined,
            }
        },
    };

    // compact (normal) keyboard, normal size
    if (layout.KeyboardLayout == KeyboardLayout.Compact && layout.KeyboardSize == KeyboardSize.Normal) {
        theme.keyboard = {
            ...theme.keyboard,
            container: {
                height: responsiveSize(22.53, height, width),
            },
            key: {
                fontSize: responsiveFontSize(3.46, height, width),
                width: '9%',
                minWidth: responsiveSize(1.33, height, width),
                maxWidth: responsiveSize(4.8, height, width),
                height: '100%',
                margin: responsiveSize(0, height, width),
            },
            tip: {
                fontSize: responsiveFontSize(3.5, height, width),
                width: responsiveSize(6, height, width),
                height: responsiveSize(6, height, width),
            },
            delete: {
                fontSize: responsiveFontSize(2.4, height, width),
                iconWidth: responsiveSize(3, height, width),
                iconHeight: responsiveSize(2.2, height, width),
                containerHeight: responsiveFontSize(4, height, width),
            }
        };
    }

    // compact (normal) keyboard, large size
    if (layout.KeyboardLayout == KeyboardLayout.Compact && layout.KeyboardSize == KeyboardSize.Large) {
        theme.keyboard = {
            ...theme.keyboard,
            container: {
                height: responsiveSize(28, height, width),
            },
            key: {
                fontSize: responsiveFontSize(3.5, height, width),
                width: responsiveSize(5.4, height, width),
                height: responsiveSize(5.4, height, width),
                margin: responsiveSize(0.2, height, width),
            },
            tip: {
                fontSize: responsiveFontSize(5.17, height, width),
                width: responsiveSize(8, height, width),
                height: responsiveSize(8, height, width),
            },
            delete: {
                fontSize: responsiveFontSize(2.81, height, width),
                iconWidth: responsiveSize(2.72, height, width),
                iconHeight: responsiveSize(2.31, height, width),
                containerHeight: responsiveFontSize(5, height, width),
            }
        };
    }

    // wide normal keyboard
    if (layout.KeyboardLayout == KeyboardLayout.Wide && layout.KeyboardSize == KeyboardSize.Normal) {
        theme.keyboard = {
            ...theme.keyboard,
            container: {
                height: responsiveSize(22.53, height, width),
            },
            key: {
                fontSize: responsiveFontSize(3.46, height, width),
                width: responsiveSize(4.8, height, width),
                height: responsiveSize(4.8, height, width),
                margin: responsiveSize(0.26, height, width),
            },
            tip: {
                fontSize: responsiveFontSize(4, height, width),
                width: responsiveSize(7.4, height, width),
                height: responsiveSize(7.4, height, width),
            },
            delete: {
                ...theme.keyboard.delete,
                fontSize: responsiveFontSize(3.2, height, width),
                iconWidth: responsiveSize(3.4, height, width),
                iconHeight: responsiveSize(2.66, height, width),
            }
        };
    }

    // wide xlarge keyboard
    if (layout.KeyboardLayout == KeyboardLayout.Wide && layout.KeyboardSize == KeyboardSize.XLarge) {
        theme.keyboard = {
            ...theme.keyboard,
            container: {
                height: responsiveSize(23.62, height, width),
            },
            key: {
                fontSize: responsiveFontSize(2.65, height, width),
                width: responsiveSize(5.45, height, width),
                height: responsiveSize(5.45, height, width),
                margin: responsiveSize(0.1, height, width),
            },
            tip: {
                fontSize: responsiveFontSize(3.5, height, width),
                width: responsiveSize(7.45, height, width),
                height: responsiveSize(7.45, height, width),
            },
            delete: {
                ...theme.keyboard.delete,
                fontSize: responsiveFontSize(2.65, height, width),
                iconWidth: responsiveSize(2.75, height, width),
                iconHeight: responsiveSize(2.31, height, width),
            }
        };
    }

    return theme;
};
