import {Theme} from "themes";
import {Platform, StyleSheet} from "react-native";

export default (theme: Theme) => StyleSheet.create({
    header: {
        height: 3.45 * theme.window.remSize + (Platform.OS === 'ios' ? 12 : 0),
        alignItems: 'stretch',
        justifyContent: "space-between",
        backgroundColor: '#FFFFFF',
        flexDirection: 'row',
        paddingLeft: 10,
        paddingRight: 20,
        paddingTop: (Platform.OS === 'ios' ? 12: 0),
    },
    headerCompact: {
        minHeight: 3.9 * theme.window.remSize,
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 10,
        paddingRight: 10,
    },
    headerSmall: {
        height: 2.72 * theme.window.remSize + (Platform.OS === 'ios' ? 12 : 0),
    },
    headerNormal: {
        borderBottomColor: '#E3E3E3',
        borderBottomWidth: 10
    },

    zoneContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 6,
        paddingBottom: 0
    },

    signBig: {
        height: 2.72 * theme.window.remSize,
        width: 2.72 * theme.window.remSize
    },
    signBigCompact: {
        height: 1.9 * theme.window.remSize,
        width: 1.9 * theme.window.remSize,
    },
    signBigCompactSmall: {
        height: 1.4 * theme.window.remSize,
        width: 1.4 * theme.window.remSize,
    },

    zoneName: {
        flex: 1,
        marginLeft: 10,
        fontSize: 1.63 * theme.window.remFontSize,
        color: theme.colors.primary,
        fontFamily: 'Geogrotesque-Regular'
    },
    zoneNameCompact: {
        marginLeft: 10,
        fontSize: 1.45 * theme.window.remFontSize,
        color: theme.colors.primary,
        textAlign: 'center',
        fontFamily: 'Geogrotesque-Regular'
    },
    zoneNameSmall: {
        fontSize: 1.45 * theme.window.remFontSize,
    },

    leftSide: {
        flex: 1,
        flexDirection: 'row',
        alignItems: "center",
    },
    rightSide: {
        flex: 1,
        flexDirection: 'row',
        alignContent: "center",
        alignItems: "center",
        justifyContent: "flex-end",
    },
});
