import { NativeModules, Platform } from 'react-native';
import I18n from 'i18n-js';
import Constants from "expo-constants";
//const { RNI18n } = NativeModules;

// Setup
//I18n.locale = ( Platform.OS === 'web' ? window.navigator.language.substr(0,2) : String(RNI18n.languages[0]).substr(0,2));

I18n.locale = Constants.manifest.extra.LOCALE || 'en'

// Translate
I18n.translations = {
    en: {
        "reload": "Reload",
        "back": "Back",
        "connect_failed": "Could not connect to the server",
        "check_internet": "Please check your internet connection",
    },
    et: {
        "reload": "Uuenda",
        "back": "Tagasi",
        "connect_failed": "Ei saanud serveriga ühendust",
        "check_internet": "Palun kontrolli interneti ühendust",
    },
    fi: {
        "reload": "Ladata",
        "back": "Takaisin",
        "connect_failed": "Palvelimeen ei saatu yhteyttä",
        "check_internet": "Ole hyvä ja tarkista internet yhteys",
    },
    ru: {
        "reload": "Перезагрузить",
        "back": "Назад",
        "connect_failed": "Не удалось соединиться с сервером",
        "check_internet": "Пожалуйста проверьте соединение с интернетом",
    },
    sv: {
        "reload": "Återladda",
        "back": "Tillbaka",
        "connect_failed": "Kan inte ansluta till servern",
        "check_internet": "Kontrollera din internetanslutning",
    },
    lv: {
        "reload": "Pārlādēt",
        "back": "Atpakaļ",
        "connect_failed": "Neizdevās pieslēgties serverim",
        "check_internet": "Lūdzu, pārbaudiet interneta savienojumu",
    },
    pl: {
        "reload": "Przeładować",
        "back": "Powrót",
        "connect_failed": "Połączenie z serwerem nie powiodło się",
        "check_internet": "Proszę sprawdzić połączenie z internetem",
    },
    dk: {
        "reload": "Genindlæs",
        "back": "Tilbage",
        "connect_failed": "Kunne ikke oprette forbindelse til serveren",
        "check_internet": "Tjek venligst din internetforbindelse",
    }
};

// Export
//export const getLanguages = RNI18n.getLanguages;
export const t = I18n.t.bind(I18n);
export default I18n;
