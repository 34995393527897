import {Theme} from "themes";
import {StyleSheet} from "react-native";

export default (theme: Theme) => StyleSheet.create({
    screensaverContainer: {
        minHeight: '100%',
        backgroundColor: '#000',
        minWidth: '100%',
        flex: 1,
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    screensaverImage: {
        height: 5.45 * theme.window.remSize,
        width: 5.45 * theme.window.remSize
    },
    screensaverText: {
        color: 'white',
        paddingTop: 0.9 * theme.window.remSize,
        fontSize: 1.27 * theme.window.remFontSize,
        fontFamily: 'Geogrotesque-Regular',
    },

    container: {
        flex: 1,
        backgroundColor: '#EDEDED',
    },
    middleContainer: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    middleSideBySide: {
        flexDirection: 'row',
    },
    middleSpaceBetween: {
        justifyContent: "space-between",
        marginLeft: 0.9 * theme.window.remSize,
        marginRight: 0.9 * theme.window.remSize,
    },
    middleCentered: {
        marginTop: 0.45 * theme.window.remSize,
        marginBottom: 0.45 * theme.window.remSize,
    },
    brandingContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    brandingSideBySide: {

    },
    brandingSpaceBetween: {
        flex: 1,
        alignItems: "flex-start",
    },
    brandingCentered: {
        flex: 1,
    },
    parkingCircleWrapper: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    parkingCircleImage: {
        width: '32%',
        height: undefined,
        aspectRatio: 1,
        marginTop: 1 * theme.window.remSize,
        marginBottom: 0.22 * theme.window.remSize,
    },
    inputContainer: {
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    inputContainerSideBySide: {

    },
    inputContainerWide: {
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    inputContainerSpaceBetween: {
        flex: 1,
    },
    inputContainerCentered: {
        flex: 2,
    },
    headLineContainer: {
        marginLeft: 0.27 * theme.window.remSize,
        marginRight: 0.27 * theme.window.remSize,
    },
    headLine: {
        fontSize: 1.63 * theme.window.remFontSize,
        color: theme.colors.text,
        textAlign: 'center',
        fontFamily: 'Geogrotesque-Regular',
    },
    subHeadLine: {
        fontSize: 1.45 * theme.window.remFontSize,
        color: theme.colors.text,
        textAlign: 'center',
        fontFamily: 'Geogrotesque-Regular'
    },
    keyboardContainer: {
        bottom: 0,
        width: '100%',
        zIndex: 2
    },

    languages: {
        marginTop: 0.9 * theme.window.remSize,
    },
    languagesHeader: {
        marginTop: 0,
        marginLeft: 1.81 * theme.window.remSize,
    },
    languagesCenter: {
        justifyContent: 'center',
        marginBottom: 0.9 * theme.window.remSize,
    },
    languagesTopRight: {
        alignSelf: 'flex-end',
        marginBottom: -2.99 * theme.window.remSize,
    },
    languagesLogo: {
        marginBottom: 0.9 * theme.window.remSize,
        alignSelf: "flex-start",
    },

    parkingImage: {
        width: '36%',
        height: undefined,
        aspectRatio: 1,

        marginBottom: 0.45 * theme.window.remSize,
    },
    landlord_logo: {
        marginRight: 0.9 * theme.window.remSize,
    },
    parkingProviderLogo: {
        width: '60%',
        resizeMode: 'contain',
        marginBottom: 1.08 * theme.window.remSize,
        maxWidth: 17.25 * theme.window.remSize,
        maxHeight: 17.25 * theme.window.remSize,
        marginHorizontal: 6
    },
    parkingProviderLogoMiddle: {
        alignSelf: 'center',
    },
    parkingProviderLogoSmall: {
        marginBottom: 0.9 * theme.window.remSize,
        maxWidth: 12.71 * theme.window.remSize,
        maxHeight: 12.71 * theme.window.remSize,
    },
    parkingProviderLogoCentered: {
        marginBottom: 0,
    },
});
