import React from "react";

import {
    View,
    Text,
    TouchableOpacity,
    GestureResponderEvent,
} from 'react-native';

import styles from "@components/alert/styles";

export interface Props {
    title: string
    message: string
    cancelText: string
    onCancel: (event: GestureResponderEvent) => void
}

const Flag = ({title, message, onCancel, cancelText}: Props) => {
    return (
        <View style={styles.popupAlert}>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.message}>{message}</Text>
            <TouchableOpacity onPress={onCancel} style={styles.buttonTextWrapper}>
                <Text style={styles.cancel}>{cancelText}</Text>
            </TouchableOpacity>
        </View>
    );
}

export default Flag;
