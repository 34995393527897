import {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {Animated, SafeAreaView, StyleSheet, View} from "react-native";
import * as SplashScreen from "expo-splash-screen";
import * as Font from "expo-font";
import Constants from "expo-constants";
import {fetchSettings, KioskStatus, setStatus, reset} from "store/kioskSlice";
import {useDispatch, useSelector} from "react-redux";
import {readStateFromMemory, RootState, store} from "store";
import * as Linking from "expo-linking";
import {authenticate} from "store/authSlice";
import {setLayout, setQrCode} from "store/settingsSlice";
import {getBoolean} from "utils/helpers";
import AlertPopup from "components/alert";
import {t} from "utils/i18n";

let customFonts = {
    'Geogrotesque-Regular': require('../../assets/fonts/geogrotesque-regular-webfont.ttf'),
};

function AnimatedAppLoader({ children, image }) {
    const [isSplashReady, setSplashReady] = useState(false);

    useEffect(() => {
        async function prepare() {
            //if we are using an url, then wait for the image to download
            //await Asset.fromURI(image.uri).downloadAsync();
            setSplashReady(true);
        }

        prepare();
    }, [image]);

    if (!isSplashReady) {
        return null;
    }

    return <AnimatedSplashScreen image={image}>{children}</AnimatedSplashScreen>;
}

function AnimatedSplashScreen({ children, image }) {
    const dispatch = useDispatch();
    const animation = useMemo(() => new Animated.Value(1), []);
    const [countdown, setCountdown] = useState(0);
    const {status, error} = useSelector((state: RootState) => state.kiosk);
    const {qr_code_enabled} = useSelector((state: RootState) => state.kiosk.settings);

    const url = Linking.useURL();

    useEffect(() => {
        if (status !== KioskStatus.initialized) {
            return;
        }

        console.debug('app is initialized, fetch data', status);

        // declare the async data fetching function
        const fetchData = async () => {
            await Animated.timing(animation, {
                toValue: 0,
                duration: 1000,
                useNativeDriver: true,
            });

            // fetch kiosk settings
            await dispatch(fetchSettings());

            // if kiosk allows to use qrcode make it enabled by default
            if (store.getState().kiosk.settings.qr_code_enabled) {
                dispatch(setQrCode(true))
            }

            // now read state from memory
            await readStateFromMemory(dispatch);

            // if we have hash in the url then try to authenticate using it
            if (url) {
                const {queryParams} = Linking.parse(url);

                if (queryParams.hash) {
                    await dispatch(authenticate(queryParams.hash));
                }

                if (queryParams.layout) {
                    await dispatch(setLayout(queryParams.layout));
                }

                if (queryParams.qrCode) {
                    await dispatch(setQrCode(getBoolean(queryParams.qrCode)));
                }
            }
        }

        // call the function
        fetchData();
    }, [status]);

    // automatically reload the app if it fails to fetch data
    useEffect(() => {
        if (status !== KioskStatus.failed) {
            return;
        }

        console.log("app load failed, auto reload")

        const autoReloadSeconds = 10;

        setCountdown(autoReloadSeconds);

        const interval = setInterval(() => { setCountdown(current => current - 1); }, 1000)
        const timer = setTimeout(() => reloadApp(), autoReloadSeconds * 1000);

        return () => {
            clearInterval(interval);
            clearTimeout(timer);
        };
    }, [status]);

    const onImageLoaded = useCallback(async () => {
        if (status > KioskStatus.initializing) {
            return;
        }

        console.debug('app is not initialized, load resources', status);

        try {
            await SplashScreen.hideAsync();
            // Load stuff
            await Promise.all([
                Font.loadAsync(customFonts)
            ]);
        } catch (e) {
            // handle errors
        } finally {
            dispatch(setStatus(KioskStatus.initialized));
        }
    }, []);

    const reloadApp = () => {
        dispatch(reset());
    }

    if (error !== null) {
        return (
            <AlertPopup title={t(error.name)}
                        message={t(error.message)}
                        cancelText={t('reload') + ' (' + countdown + ')'}
                        onCancel={() => reloadApp()}/>
        );
    }

    return (
        <Fragment>
            <SafeAreaView style={{ flex: 0, backgroundColor: '#EDEDED' }} />
            <SafeAreaView style={{ flex: 1, backgroundColor: '#1A2A3A' }}>
                {status === KioskStatus.ready && children}
                {status !== KioskStatus.ready && (
                    <Animated.View
                        pointerEvents="none"
                        style={[
                            StyleSheet.absoluteFill,
                            {
                                backgroundColor: Constants.manifest.splash.backgroundColor,
                                opacity: animation,
                            },
                        ]}
                    >
                        <Animated.Image
                            style={{
                                width: "100%",
                                height: "100%",
                                resizeMode: Constants.manifest.splash.resizeMode || "contain",
                                transform: [
                                    {
                                        scale: animation,
                                    },
                                ],
                            }}
                            source={require("../../assets/splash.png")}
                            onLoadEnd={onImageLoaded}
                            fadeDuration={0}
                        />
                    </Animated.View>
                )}
            </SafeAreaView>
        </Fragment>
    );
}

export default AnimatedAppLoader;
