/**
 * Input PIN
 */
import React from "react";
import {
  Image,
  StatusBar,
  Text,
  TouchableOpacity,
  View
} from 'react-native';

import {useDispatch, useSelector} from 'react-redux';
import themedStyles from './styles';
import {RootState} from "store";
import VersionText from "@components/version";
import KioskKeyboard from "@components/kiosk_keyboard";
import {t} from '@utils/i18n';
import Languages from "@components/languages";
import {changeLanguage, switchLayout, switchQrCode} from "store/settingsSlice";
import {login, logout} from "store/authSlice";
import AlertPopup from "@components/alert";
import { useTheme } from '@react-navigation/native';
import {LanguageBarPosition, LogoPosition, LogoSpacing} from "themes/layout";
import {LayoutSvgImage, qrCodeImage, qrCodeOffImage} from "utils/assets";
import InputBox from "components/input_box";
import {clearValue} from "store/dataSlice";
import Constants from "expo-constants";
import {Theme} from "themes";

const LAYOUT_FIXED = Constants.manifest.extra.LAYOUT_FIXED || false;

function PinView() {
  const {qr_code_enabled} = useSelector((state: RootState) => state.kiosk.settings);
  const {qrCode} = useSelector((state: RootState) => state.settings);
  const theme = useTheme() as Theme
  const styles = themedStyles(theme);

  const dispatch = useDispatch();
  const {error} = useSelector((state: RootState) => state.auth);

  const onLanguageChange = (l) => {
    dispatch(changeLanguage(l))
  }

  const onSubmit = (pin) => {
    dispatch(login(pin))
        .unwrap()
        .then(() => {
          dispatch(clearValue());
        });
  }

  const forceLayout = () => {
    dispatch(switchLayout())
  }

  const toggleQrCode = () => {
    dispatch(switchQrCode())
  }

  StatusBar.setBarStyle('default')

  if (error) {
    return (
        <AlertPopup title={t(error.name)}
                    message={t(error.message)}
                    cancelText={t('back')}
                    onCancel={() => { dispatch(logout()) }}/>
    );
  }

  const layoutButtons = () => {
    return <View style={styles.forceLayoutButton}>
      { !LAYOUT_FIXED ? <TouchableOpacity style={styles.forceLayoutButtonWrapper} onPress={forceLayout}>
        <View style={styles.forceLayoutButtonImage}>
          <LayoutSvgImage/>
        </View>
      </TouchableOpacity> : null
      }
      { qr_code_enabled ? <TouchableOpacity onPress={toggleQrCode}>
        <View>
          <Image source={qrCode ? qrCodeImage : qrCodeOffImage} style={styles.switchQrCodeButtonImage} />
        </View>
      </TouchableOpacity> : null
      }
    </View>
  }

  return (
    <View style={styles.container}>
      { [LanguageBarPosition.Top, LanguageBarPosition.TopRight].includes(theme.layout.LanguageBarPosition) ? <Languages style={[styles.languages, theme.layout.LanguageBarPosition == LanguageBarPosition.Top ? styles.languagesCenter : styles.languagesTopRight]} theme={theme} onChange={onLanguageChange}/> : null }

      <View style={[styles.middleContainer, (theme.layout.LogoPosition == LogoPosition.SideBySide ? styles.middleSideBySide : null), (theme.layout.LogoSpacing == LogoSpacing.SpaceBetween ? styles.middleSpaceBetween : null), (theme.layout.LogoSpacing == LogoSpacing.Centered ? styles.middleCentered : null)]}>
        <View style={[styles.brandingContainer, (theme.layout.LogoPosition == LogoPosition.SideBySide ? styles.brandingSideBySide : null), (theme.layout.LogoSpacing == LogoSpacing.SpaceBetween ? styles.brandingSpaceBetween : null), (theme.layout.LogoSpacing == LogoSpacing.Centered ? styles.brandingCentered : null)]}>
          { [LanguageBarPosition.Logo, LanguageBarPosition.Header].includes(theme.layout.LanguageBarPosition) ? <Languages style={styles.languagesLogo} theme={theme} onChange={onLanguageChange}/> : null }
        </View>
        <View style={[styles.inputContainer, (theme.layout.LogoPosition != LogoPosition.SideBySide ? styles.inputContainerWide : null), (theme.layout.LogoSpacing == LogoSpacing.SpaceBetween ? styles.inputContainerSpaceBetween : null), (theme.layout.LogoSpacing == LogoSpacing.Centered ? styles.inputContainerCentered : null)]}>
          <View style={styles.headLineContainer}>
            <Text style={styles.headLine}>{t('enter_pin')}</Text>
          </View>
          <InputBox theme={theme} placeholderText={t('use_the_keyboard')} minLength={2} secureTextEntry={true} onSubmit={onSubmit}/>
        </View>
      </View>
      <View style={styles.keyboardContainer}>
        <KioskKeyboard theme={theme}/>
      </View>
      { layoutButtons() }
      <VersionText dark={false} />
    </View>
  );
}

export default PinView;
