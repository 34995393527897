import {Platform, StyleSheet} from "react-native";
import {Theme} from "themes";

export default (theme: Theme) => StyleSheet.create({
    timeDate: {
        flex: 1,
    },
    timeDateCompact: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 6
    },
    time: {
        textAlign: 'right',
        color: theme.colors.primary,
        fontSize: 0.9 * theme.window.remFontSize,
        fontFamily: 'Geogrotesque-Regular'
    },
    timeCompact: {
        fontSize: 0.9 * theme.window.remFontSize,
    },
    date: {
        textAlign: 'right',
        color: theme.colors.primary,
        fontSize: 0.9 * theme.window.remFontSize,
        fontFamily: 'Geogrotesque-Regular'
    },
    dateCompact: {
        fontSize: 0.9 * theme.window.remFontSize,
    }
});
