import React from 'react';
import {GestureResponderEvent, Image, TouchableOpacity, View} from 'react-native';
import {HeaderLayout, HeaderSize} from 'themes/layout';
import {FlagsImages} from 'utils/assets';

import themedStyles from '@components/flag/styles';
import {Theme} from "themes";

export interface Props {
    theme: Theme
    language: string
    active: boolean
    onPress: (e: GestureResponderEvent) => void
}

const Flag = ({theme, language, active, onPress}: Props) => {

    const styles = themedStyles(theme);
    const small = theme.layout.HeaderSize == HeaderSize.Small
    const compact = theme.layout.HeaderLayout == HeaderLayout.Compact

    return (
      <TouchableOpacity onPress={onPress}>
        <View style={[styles.flagImageWrapper, active && styles.activeFlagImageWrapper]}>
          <Image source={FlagsImages[language]} style={[styles.flagImage, compact ? styles.flagImageCompact : null, small ? styles.flagImageSmall : null]} />
        </View>
      </TouchableOpacity>
    );
}

export default Flag;
