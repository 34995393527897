import Constants from 'expo-constants';
import {RootState} from "../store";
import {fetchWithTimeout} from "utils/helpers";
import {Platform} from "react-native";

// in case of web, we need to support both http and https so that web kiosk that are hardcoded to use http keep on working as expected.
const BASE_URL = (Platform.OS === 'web' ? '' : 'https:') + (Constants.manifest.extra.BASE_URL || '//localhost:8081/api');
const REQUEST_TIMEOUT = 30; // 30 seconds

interface ApiClient {
    get(endpoint: string): Promise<Response>;
    post(endpoint: string, body: any): Promise<Response>;
}

const restApi = function (state: RootState): ApiClient {
    return {
        get(endpoint: string) {
            return fetchWithTimeout(BASE_URL + '/' + endpoint, {}, REQUEST_TIMEOUT)
        },
        post(endpoint: string, body: any = null) {
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            };

            if (state.auth.token !== '') {
                headers['Authorization'] = `Bearer ${state.auth.token}`;
            }

            return fetchWithTimeout(BASE_URL + '/' + endpoint, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            }, REQUEST_TIMEOUT)
        },
    };
};

export default restApi;
