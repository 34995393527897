import {StyleSheet} from "react-native";
import {Theme} from "themes";

export default (theme: Theme) => StyleSheet.create({
    keyboardContainer: {
        height: theme.keyboard.container.height,
        backgroundColor: '#1A2A3A',
        borderTopWidth: 0.66 * theme.window.remSize,
        borderTopColor: '#E3E3E3',
        paddingTop: 0.26 * theme.window.remSize,
        paddingBottom: 0.26 * theme.window.remSize,
    },
    keyboardContainerWeb: {
        alignSelf: 'stretch'
    },

    keysRow: {
        flex: 1,
        height: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    keysRowWeb: {
        alignSelf: 'stretch'
    },

    keysWideRowContainer: {
        width: '100%',

        flexDirection: 'row',
        justifyContent: 'center',
    },
    keysWideRow: {
        flex: 7,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    numsRow: {
        flex: 2,
        flexDirection: 'row',
        justifyContent: 'center',
    },

    // Delete button

    deleteContainer: {
        height: theme.keyboard.delete.containerHeight,
    },
    deleteContainerCompact: {
        flexDirection: 'column',
        justifyContent: 'center',
    },

    deleteButton: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0.26 * theme.window.remSize,
    },

    deleteButtonIcon: {
        marginRight: 0.45 * theme.window.remSize,
        width: theme.keyboard.delete.iconWidth,
        height: theme.keyboard.delete.iconHeight,
    },

    deleteButtonText: {
        fontSize: theme.keyboard.delete.fontSize,
        color: '#E8C005',
        fontFamily: 'Geogrotesque-Regular',
        textAlign: 'center'
    },

    deleteContainerUltraCompact: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    deleteButtonIconUltraCompact: {
        marginLeft: 0.66 * theme.window.remSize,
        marginRight: 0,
    },

});
