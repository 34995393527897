import React, {useState} from "react";
import {Text, TouchableOpacity, View} from "react-native";
import themedStyles from "@components/key/styles";
import {Theme} from "themes";
import {KeyboardLayout} from "themes/layout";

export interface Props {
    theme: Theme
    onPress: (child: string) => void;
}

const Key = ({theme, onPress, ...props}: React.PropsWithChildren<Props>) => {
    const styles = themedStyles(theme);
    const [pressed, setPressed] = useState(false);

    const compact = theme.layout.KeyboardLayout == KeyboardLayout.Compact

    const onPressIn = () => {
        setPressed(true)
    }

    const onPressOut = () => {
        setPressed(false)
    }

    return (
        <View style={[styles.keyContainer, compact ? styles.keyContainerCompact : null]}>
            { pressed ?
                (<View style={[styles.tipContainer, compact ? styles.tipContainerCompact : null]}>
                    <Text style={[styles.tipText, compact ? styles.tipTextCompact : null]}>
                        {props.children}
                    </Text>
                </View>) : null }
            <TouchableOpacity
                style={styles.touchBox}
                activeOpacity={1.0}
                onPressIn={onPressIn}
                onPressOut={onPressOut}
                onPress={() => { onPress(props.children.toString()) }}
            >
                <View style={ [
                    styles.keyTextWrapper,
                    pressed ? styles.keyTextWrapperPressed : null
                ] }>
                    <Text adjustsFontSizeToFit={true} style={[
                        styles.keyText,
                        compact ? styles.keyTextCompact : null,
                        pressed ? styles.keyTextPressed : null
                    ]}>{props.children}</Text>
                </View>
            </TouchableOpacity>
        </View>
    )
}

export default Key;
