import blueTheme from './blue';
import greenTheme from './green';
import plTheme from './pl';

import * as React from 'react';
import ThemeContext from '@react-navigation/native/src/theming/ThemeContext';
import Constants from "expo-constants";
import {Theme as BaseTheme} from "@react-navigation/native/src/types";
import create from "themes/scale";
import Api from "themes/stylesheet/api";
import {LayoutConfig} from "themes/layout";

const THEME = Constants.manifest.extra.THEME || 'europark_ee';

export interface Theme extends BaseTheme {
    layout: LayoutConfig;
    window: {
        height: number;
        width: number;
        scale: number;
        fontScale: number;
        remSize: number;
        remWidth: number;
        remHeight: number;
        remFontSize: number;
    };
    buttons: {
        primary: string;
        result: string;
    };
    wells: {
        primary: string;
        primaryText: string;
        primaryTextFontWeight: string;
        qrCodeBorder: string;
    };
    keyboard: {
        container: {
            height: number;
        };
        key: {
            fontSize: number;
            width: number|string;
            minWidth: number|string;
            maxWidth: number|string;
            height: number|string;
            margin: number;
        };
        tip: {
            fontSize: number;
            width: number;
            height: number;
        };
        delete: {
            fontSize: number;
            iconWidth: number;
            iconHeight: number;
            containerHeight: number;
        };
    };
}

export const getThemeStyles = (layout: LayoutConfig, height: number, width: number, scale: number, fontScale: number):Theme => {
    switch (THEME) {
        case 'pl':
            return plTheme(layout, height, width, scale, fontScale);
        case 'green':
            return greenTheme(layout, height, width, scale, fontScale);
        default:
            return blueTheme(layout, height, width, scale, fontScale);
    }
}

export const ThemedStyledSheet = new Api();

export const StyledSheetCreate = create;
