/**
 * Extended StyleSheet API
 */

import {Dimensions, StyleSheet} from 'react-native';
import Sheet from './sheet';
import {Theme} from "themes";
import {KeyboardLayout, KeyboardSize, LayoutConfig} from "themes/layout";
import {calculateSizes} from "utils/helpers";

const BUILD_EVENT = 'build';

export default class EStyleSheet {
  /**
   * Constructor
   */
  constructor() {
    this._proxyToOriginal();
  }

  /**
   * Creates stylesheet that will be calculated after build
   * @param {Object} obj
   * @returns {Object}
   */
  create(theme: Theme, layout: LayoutConfig, obj) {
    const sheet = new Sheet(obj);
    const sizes = calculateSizes(layout.KeyboardSize)

    let vars = {...theme.globals, '$layout': layout, '$containerHeight': sizes.containerHeight, '$keyHeight': sizes.rowHeight, '$charFontSize': sizes.charFontSize};

    // change root rem size according to screen width :?
    // vars['$rem'] = width > 340 ? 18 : 16;

    sheet.calc(vars, {
      'header': layout.HeaderLayout,
      'header-size': layout.HeaderSize,
      'keyboard': layout.KeyboardLayout,
      'keyboard-size': layout.KeyboardSize,
      'input-size': layout.InputSize,
    });

    return sheet.getResult();
  }

  _proxyToOriginal() {
    // see: https://facebook.github.io/react-native/docs/stylesheet.html
    const props = [
      'setStyleAttributePreprocessor',
      'hairlineWidth',
      'absoluteFill',
      'absoluteFillObject',
      'flatten',
    ];
    props.forEach(prop => {
      Object.defineProperty(this, prop, {
        get: () => StyleSheet[prop],
        enumerable: true,
      });
    });
  }
}
