import {Theme} from "themes";
import {StyleSheet} from "react-native";
export default (theme: Theme) => StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#EDEDED',
    },
    middleContainer: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    middleSideBySide: {
        flexDirection: 'row',
    },
    middleSpaceBetween: {
        justifyContent: "space-between",

        marginLeft: 0.9 * theme.window.remSize,
        marginRight: 0.9 * theme.window.remSize,
    },
    middleCentered: {
        marginTop: 0.45 * theme.window.remSize,
        marginBottom: 0.45 * theme.window.remSize,
    },
    brandingContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    brandingSideBySide: {
        marginRight: 1.63 * theme.window.remSize,
    },
    brandingSpaceBetween: {
        flex: 1,
    },
    brandingCentered: {
        flex: 1,
    },
    inputContainer: {
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    inputContainerWide: {
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    inputContainerSpaceBetween: {
        flex: 1,
    },
    inputContainerCentered: {
        flex: 2,
    },
    headLineContainer: {
        marginTop: 1.36 * theme.window.remSize,

        marginLeft: 0.27 * theme.window.remSize,
        marginRight: 0.27 * theme.window.remSize
    },
    headLine: {
        fontSize: 1.86 * theme.window.remFontSize,
        color: theme.colors.text,
        textAlign: 'center',
        fontFamily: 'Geogrotesque-Regular',
    },
    subHeadLine: {
        fontSize: 1.33 * theme.window.remFontSize,
        color: theme.colors.text,
        textAlign: 'center',
        fontFamily: 'Geogrotesque-Regular'
    },
    keyboardContainer: {
        bottom: 0,
        width: '100%',
        zIndex: 2,
    },
    languages: {
        marginTop: 1.2 * theme.window.remSize
    },
    languagesCenter: {
        justifyContent: 'center',
    },
    languagesTopRight: {
        alignSelf: 'flex-end',
        marginBottom: -2.99 * theme.window.remSize
    },
    languagesLogo: {
        alignSelf: "flex-start",
    },
    forceLayoutButton: {
        position: 'absolute',
        padding: 1.2 * theme.window.remSize,
        left: 0,
        top: 0,
        zIndex: 100
    },
    forceLayoutButtonWrapper: {
        marginBottom: 0.25 * theme.window.remSize,
    },
    forceLayoutButtonImage: {
        width: 3.06 * theme.window.remSize,
        height: 3.06 * theme.window.remSize,
    },
    switchQrCodeButtonImage: {
        width: 3.06 * theme.window.remSize,
        height: 3.06 * theme.window.remSize,
    },
})
