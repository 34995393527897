import React from "react";
import {StyleSheet, Text, TextStyle} from 'react-native';
import Constants from "expo-constants";
import styles from './styles';

const KIOSK_VERSION = Constants.manifest.extra.KIOSK_VERSION || '0.0.0';
export interface Props {
    dark: boolean
    style?: TextStyle|TextStyle[]
}

export default (props: Props) => {
    return <Text style={[props.dark ?
        StyleSheet.flatten([styles.versionText, {color: '#303F4F'}]) :
        styles.versionText
    , props.style]}>Ver: {KIOSK_VERSION}</Text>
}
